import * as types from './mutation-types'
import subscriptionService from '../api/subscription-service'

export default {
  getPuzzleOrders(store, subscription_id) {
    store.commit(types.GET_PUZZLE_ORDERS);
    subscriptionService.getPuzzleOrders(subscription_id, puzzle_orders => {
      store.commit(types.SET_PUZZLE_ORDERS, puzzle_orders)
    })
  },

}