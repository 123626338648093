<template>
  <v-card class="account-card">
    <v-card-title>
      Puzzle Level
    </v-card-title>
    <v-card-subtitle>
      Want to change difficulty level?
      Current level: {{getLevelName()}}
    </v-card-subtitle>
    <v-img contain max-height="150px" :src="img_src"></v-img>
    <div class="card-spacer"></div>
    <v-card-actions>
      <v-btn :to="{ name: 'level', params: {subscription_id: this.activeSubscriptionId} }" class="yellow">Edit Level</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'level',
  components: {
  },
  computed: {
    ...mapState({
      levels: 'levels'
    }),

    ...mapGetters({
      activeSubscriptionId: 'getActiveSubscriptionId',
      activeSubscription: 'getActiveSubscription'
    }),
    img_src () { return "https://completing-the-puzzle.s3.amazonaws.com/public/portal/levels/"+this.getLevelName()+".png"}
  },
  methods: {
    getLevelName: function() {
      return this.levels[this.activeSubscription.attributes.levelId].attributes.name;
    },
  },
}
</script>
