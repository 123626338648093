<template>
  <v-card class="account-card">
    <v-card-title>
      Shipping Address
    </v-card-title>
    <v-card-subtitle>
      Moving? Update your address
    </v-card-subtitle>
    <v-img contain max-height="150px" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/shipping.png"></v-img>
    <div class="card-spacer"></div>
    <v-card-actions>
      <v-btn :to="{name: 'shipping_address', params: {subscription_id: this.activeSubscriptionId} }" class="red white--text">Update Your Address</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'shipping_address',
  components: {
  },
  methods: {
  },
  data: function(){
    return {
    }
  },
  computed: {
    ...mapGetters({
      activeSubscriptionId: 'getActiveSubscriptionId'
    })
  }
}
</script>
