<template>
  <v-app id="app" >

    <router-view :key="$route.fullPath"></router-view>

    <v-snackbar
        v-model="error_snackbar"
        :timeout="2000"
        color="error"
        :top=true
    >
      <div v-html="error_message"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="error_snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
        v-model="success_snackbar"
        :timeout="2000"
        color="success"
        :top=true
    >
      <div v-html="success_message"></div>

      <template v-slot:action="{ attrs }">
        <v-btn
            text
            v-bind="attrs"
            @click="success_snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapState } from 'vuex'
import login from "./packs/portal/components/login";
export default {
  components: {
    login
  },
  computed: {
    ...mapState({
      notifications: 'notifications',
      customer: 'customer'
    }),
    initials: function(){
      if (this.customer.attributes) {
        return this.customer.attributes.name.split(" ").map((n) => n[0]).join("");
      }else {
        return "";
      }
    },
    error_message_watcher: function(){
      return (this.notifications.error_message)
    },
    success_message_watcher: function(){
      return (this.notifications.success_message)
    },
  },
  created: function(){

  },
  watch: {
    'error_message_watcher': function(error_message) {
      if (error_message != null) {
        this.error_message = error_message
        this.error_snackbar = true
      }

      this.notifications.error_message = null
    },
    'success_message_watcher': function(success_message) {
      if (success_message != null) {
        this.success_message = success_message
        this.success_snackbar = true
      }

      this.notifications.success_message = null
    }
  },
  data: function () {
    return {
      picker: new Date().toISOString().substring(0,10),
      error_snackbar: false,
      error_message: null,
      success_snackbar: false,
      success_message: null,
    }
  }
}
</script>

<style scoped>
.logo {
  max-width: 150px;
}
.splash {
  background-image: url("/portal/bg-product.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  box-shadow: inset 0 0 0 1000px rgba(0,0,0,.2);
}

</style>
