<template>
  <div>
    <v-layout row wrap>
      <v-flex d-flex xs6 md4 lg3 v-for="puzzleProduct in localPuzzleProducts" :key="puzzleProduct.id" class="pa-2">
        <v-card  class="flex-grow-1 " >
          <v-img
              :lazy-src="puzzleProduct.attributes.extraSmallImageSrc"
              :src="puzzleProduct.attributes.mediumImageSrc"
              class="white--text align-end "
              aspect-ratio="1"
          >
          </v-img>
          <v-card-subtitle class="card-subtitle">
            <span v-if="puzzleProduct.attributes.outOfStock">Out of Stock<br/></span>

            <b>Name:</b>  {{puzzleProduct.attributes.name}}<br/>
            <b>Brand:</b> {{getBrandName(puzzleProduct.attributes.brandId)}}<br/>
            <b>Piece Count:</b> {{puzzleProduct.attributes.pieceCount}}<br/>
            <b>Dimensions:</b> {{puzzleProduct.attributes.length}}"x{{puzzleProduct.attributes.width}}"<br/>
            <b>Piece Type:</b>  {{puzzleProduct.attributes.isLargeSized ? 'Large' : 'Standard'}}<br/>
            <div v-html="getThemes(puzzleProduct.id)"></div>


          </v-card-subtitle>
          <v-card-actions class="bottom-actions">
            <v-btn icon @click="addWishlist(puzzleProduct.id)" v-if="!isWishlist(puzzleProduct.id)">
              <v-icon>mdi-heart-outline</v-icon>
            </v-btn>
            <v-btn icon @click="removeWishlist(puzzleProduct.id)" v-if="isWishlist(puzzleProduct.id)">
              <v-icon color="red">mdi-heart</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
    <v-layout flex justify-center >
        <v-btn  class="success mt-16" v-show="totalAmount > totalShown" @click="showMore">Show More</v-btn>
    </v-layout>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'wishlist_list',
  components: {
  },
  methods: {
    addWishlist(id) {
      this.$store.dispatch("addWishlist",id);
      this.localWishlistProductIds.push(parseInt(id));
    },
    removeWishlist(id) {
      this.$store.dispatch("removeWishlist",id);
      this.localWishlistProductIds.splice(this.localWishlistProductIds.indexOf(parseInt(id)),1);
    },
    isWishlist: function(id){
      return (this.localWishlistProductIds.indexOf(parseInt(id)) > -1);
    },
    getBrandName: function(brandId) {
      return this.brands[brandId].attributes.name;
    },
    getThemes: function(productId) {
      let str = "";
      let _themes = this.themes;
      if (this.themesByProduct[productId]) {
        this.themesByProduct[productId].forEach(function(themeId) {
          str += "<span class='theme theme-"+_themes[themeId].attributes.name+"'>"+_themes[themeId].attributes.name+"</span>";
        });
      }
      return str;
    },
    onPuzzleProductsUpdated: function(){
      this.localPuzzleProducts = this.puzzleProducts.slice(0,this.showAmount);
      this.totalAmount = this.puzzleProducts.length;
      this.totalShown = this.showAmount;
    },
    showMore: function(){
      this.localPuzzleProducts.push(...this.puzzleProducts.slice(this.totalShown,this.totalShown + this.showAmount));
      this.totalShown = this.totalShown + this.showAmount;
    }
  },
  created: function(){
  },
  mounted: function(){
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      brands: 'getBrands',
      themesByProduct: 'getPuzzleProductThemesByProduct',
      themes: 'getThemes'
    })
  },
  data: function(){
    return {
      totalShown: 48,
      totalAmount: 0,
      showAmount: 48,
      localPuzzleProducts: [],
      themeColors: {
        landscape: {
          hex: "#FFCDD2",
          color: "red"
        },
        gradient: {
          color: "purple",
          hex: "#E1BEE7"
        },
        fantasy: {
          color: "deep-purple",
          hex: "#D1C4E9"
        },
        disney: {
          color: "cyan",
          hex: "#B2EBF2"
        },
        destination: {
          color: "teal",
          hex: "#B2DFDB"
        },
        finearts: {
          color: "blue-grey",
          hex: "#CFD8DC"
        },
        collage: {
          color: "blue",
          hex: "#90CAF9"
        },
        animals: {
          color: "light-blue",
          hex: "#B3E5FC"
        },
        americana: {
          color: "amber",
          hex: "#FFECB3"
        },
        holiday: {
          color: "green",
          hex: "#C8E6C9"
        },
      }
    }
  },
  watch: {
    // call again the method if the route changes
    'puzzleProducts': 'onPuzzleProductsUpdated',
  },
  props: ['puzzleProducts','localWishlistProductIds']
}
</script>
<style scoped>
.title-bg {
  background-color: rgba(0,0,0,0.2);
}
.card-subtitle {
  margin-bottom: 52px;
}
.bottom-actions{
  position: absolute;
  bottom: 0;
}
</style>
<style>
.theme {
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 4px;
  margin-top: 4px;
  display: inline-block;
}

.theme-landscape {
  background-color: #FFCDD2;
}
.theme-gradient {
  background-color: #E1BEE7;
}
.theme-fantasy {
  background-color: #D1C4E9;
}
.theme-disney {
  background-color: #B2EBF2;
}
.theme-destination {
  background-color: #B2DFDB;
}
.theme-collage {
  background-color: #90CAF9;
}
.theme-fine {
  background-color: #C8E6C9;
}
.theme-animals {
  background-color: #B3E5FC;
}
.theme-americana {
  background-color: #FFECB3;
}
.theme-holiday {
  background-color: #CFD8DC;
}
</style>
