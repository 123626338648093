<template>
  <div>
    <div v-if="activeSubscriptionId != null">
      <v-container>
        <SubscriptionStatus></SubscriptionStatus>
      </v-container>
      <v-container >
        <v-row class="justify-center">
          <v-col sm="12" md="6">
            <Billing></Billing>
          </v-col>
          <v-col sm="12" md="6">
            <Help></Help>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import SubscriptionStatus from './dashboard/subscription_status'
import Billing from './dashboard/billing'
import Help from './dashboard/help'
import ShippingAddress from './dashboard/shipping_address'
import {mapGetters} from "vuex";
export default {
  name: 'subscription',
  components: {
    Billing, SubscriptionStatus, Help, ShippingAddress
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      activeSubscriptionId: 'getActiveSubscriptionId'
    })
  },
  watch: {
  },
  data: function(){
    return {
    }
  }
}
</script>