<template>
  <div>
    <v-container class="sticky-breadcrumbs">
      <v-breadcrumbs>
        <v-breadcrumbs-item exact :to="{path: '/portal/subscriptions/'+this.activeSubscriptionId+'/home'}">Home</v-breadcrumbs-item>
        <v-breadcrumbs-divider>
          <v-icon>mdi-chevron-right</v-icon>
        </v-breadcrumbs-divider>
        <v-breadcrumbs-item disabled>Update Puzzle Level</v-breadcrumbs-item>
      </v-breadcrumbs>
    </v-container>
    <v-container>
      <p>
        Select puzzle themes that you <span style="text-decoration: underline; font-weight: bold">don't</span> like and hit save
      </p>
      <v-row class="justify-center">
        <v-col xs="12" md="4" v-for="(theme, id) in themes" :key="theme.id" style="flex-basis: inherit">
          <v-card :class="{ active: (subscriptionThemesContains(id)) }" @click="selectTheme(id)">
            <v-img contain :src="getImg(theme.attributes.name)"></v-img>
            <v-card-subtitle class="text-center">
              <p class="theme-name mb-0">{{theme.attributes.name}}</p>
            </v-card-subtitle>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-btn
            color="success"
            class="mr-4"
            @click="submit"
        >
          Save
        </v-btn>
        <v-btn
            color="warning"
            class="mr-4"
            :to="'/portal/subscriptions/'+this.activeSubscription.id+'/home'"
        >
          Cancel
        </v-btn>
      </div>
    </v-container>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'manage_themes',
  components: {
  },
  methods: {
    submit () {
      this.$store.dispatch("updateSubscriptionThemes", this.localThemeIds)
    },
    selectTheme (id) {
      let index = this.localThemeIds.indexOf(parseInt(id));
      if (index > -1){
        this.localThemeIds.splice(index, 1);
      } else {
        this.localThemeIds.push(parseInt(id));
      }
    },
    subscriptionThemesContains(id) {
      return (this.localThemeIds.indexOf(parseInt(id)) > -1);
    },
    getImg(name) {
      return "https://completing-the-puzzle.s3.amazonaws.com/public/portal/themes/"+name+".jpg"
    }
  },
  created: function(){
    this.$store.dispatch("getSubscriptionThemes")
    console.log("[manage theme] created");
  },
  mounted: function(){
    this.localThemeIds = this.subscriptionThemes;
    console.log("[manage theme] mounted");
  },
  watch: {
    'subscriptionThemes': function(values){
      if (values != null){
        this.localThemeIds = values;
      }
    }
  },
  computed: {
    ...mapState({
      themes: 'themes',
    }),
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
      subscriptionThemes: 'getSubscriptionThemesArray',
      activeSubscriptionId: 'getActiveSubscriptionId'
    }),
  },
  data: function(){
    return {
      localThemeIds: []
    }
  },
}
</script>
<style>
  .active {
    border: 2px solid #ed1c24 !important;
  }
  .theme-name::before {
    content: " ";
    white-space: pre;
    color: #ed1c24;
    font-size: 18px;
    line-height: 32px;
    top: 2px;
    right: 3px;
    position: relative;
    font-weight: 700;
    border: 3px solid #ed1c24;
    height: 12px;
    padding: 0 9px;
  }
  .active .theme-name::before {
    content: "\2714\fe0e" !important;
    padding: 0 3px;
  }
  .sticky-breadcrumbs {
    position: sticky;
    top: 50px;
    z-index: 1;
    background: white;
  }
</style>
