<template>
  <div>
    <v-navigation-drawer
        v-model="drawer"
        absolute app
        temporary
        class="d-md-none "
    >
      <v-container class="mb-16" >
        <h2 class="mt-8">
          Piece Type
        </h2>
        <v-checkbox hide-details v-model="largePiece" label="Large Piece" @change="filterProducts">
        </v-checkbox>
        <v-checkbox hide-details v-model="standardPiece" label="Standard Piece" @change="filterProducts">
        </v-checkbox>

        <h2 class="mt-4">
          Piece Count
        </h2>
        <v-checkbox hide-details v-for="pieceCount in pieceCounts" :value="pieceCount" :label="pieceCount.toString()" v-model="selectedPieceCounts">
        </v-checkbox>
        <v-divider class="mt-4"></v-divider>
        <h2 class="mt-4">
          Themes
        </h2>
        <v-checkbox hide-details v-for="theme in themes" :value="parseInt(theme.id)" :label="theme.attributes.name" v-model="selectedThemes" :color="themeColors[theme.attributes.name.split(' ').join('')].color">

        </v-checkbox>
        <v-divider class="mt-4"></v-divider>
        <h2 class="mt-4">
          Brands
        </h2>
        <v-checkbox hide-details v-for="brand in hardcodedBrands" :value="brand.id" :label="brand.name" v-model="brandFilters">

        </v-checkbox>

      </v-container>

    </v-navigation-drawer>
    <v-container class="mb-16">
      <v-row>
        <v-btn @click.stop="drawer = !drawer" color="blue" class="white--text ma-2 d-md-none" >Filter
          <v-icon>mdi-filter-menu-outline</v-icon>
        </v-btn>
      </v-row>
      <v-row>
        <v-col class="col-md-3 d-none d-md-block" >
          <h2 class="mt-8">
            Piece Type
          </h2>
          <v-checkbox hide-details v-model="largePiece" label="Large Piece" @change="filterProducts">
          </v-checkbox>
          <v-checkbox hide-details v-model="standardPiece" label="Standard Piece" @change="filterProducts">
          </v-checkbox>
          <h2 class="mt-8">
            Piece Count
          </h2>
          <v-checkbox hide-details v-for="pieceCount in pieceCounts" :value="pieceCount" :label="pieceCount.toString()" v-model="selectedPieceCounts">
          </v-checkbox>

          <h2 class="mt-8">
            Themes
          </h2>
          <v-checkbox hide-details v-for="theme in themes" :value="parseInt(theme.id)" :label="theme.attributes.name" v-model="selectedThemes" :color="themeColors[theme.attributes.name.split(' ').join('')].color">

          </v-checkbox>
          <h2 class="mt-8">
            Brands
          </h2>
          <v-checkbox hide-details v-for="brand in hardcodedBrands" :value="brand.id" :label="brand.name" v-model="brandFilters">

          </v-checkbox>
        </v-col>
        <v-col xs="12" md="9" >
          <WishlistList :puzzleProducts="localPuzzleProducts" :localWishlistProductIds="localWishlistProductIds">
          </WishlistList>


        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import WishlistList from './wishlist/wishlist_list'
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'catalog',
  components: {
    WishlistList
  },
  methods: {
    filterProducts: function(){
      let tmpLocalProducts = new Object();
      for (let index in Object.keys(this.puzzleProducts)) {
        let skip = false;
        let key = Object.keys(this.puzzleProducts)[index];
        if(this.largePiece || this.standardPiece) {
          skip = true
          if (this.puzzleProducts[key].attributes.isLargeSized && this.largePiece){
            skip = false;
          }
          if (!this.puzzleProducts[key].attributes.isLargeSized && this.standardPiece){
            skip = false;
          }
        }
        if (this.brandFilters.length > 0 && skip == false) {
          skip = true;
          if (this.brandFilters.indexOf(this.puzzleProducts[key].attributes.brandId) > -1){
            skip = false;
          }
        }
        if (this.selectedPieceCounts.length > 0 && skip == false){
          skip = true;
          if (this.selectedPieceCounts.indexOf(this.puzzleProducts[key].attributes.pieceCount) > -1){
            skip = false;
          }
        }
        if (this.selectedThemes.length > 0 && skip == false){
          skip = true;
          if (this.themesByProduct[this.puzzleProducts[key].id]){
            if (this.themesByProduct[this.puzzleProducts[key].id].some(r=> this.selectedThemes.indexOf(r) >= 0)) {
              skip = false;
            }
          }
        }
        if (this.puzzleProducts[key].attributes.outOfStock) {
          skip = true;
        }
        if (!skip) {
          tmpLocalProducts[key] = this.puzzleProducts[key];
        }
      }
      this.localPuzzleProducts = this.sortPuzzles(tmpLocalProducts);
    },
    sortPuzzles: function(products) {
      return Object.values(products).sort((a,b) => {return b.id - a.id});
    }
  },
  watch: {
    'wishlistProductIds': function () {
      this.localWishlistProductIds = this.wishlistProductIds;
    },
    'brandFilters': function() {
      this.filterProducts();
    },
    'selectedThemes': function() {
      this.filterProducts();
    },
    'selectedPieceCounts': function() {
      this.filterProducts();
    },
    'puzzleProducts': function() {
      this.localPuzzleProducts = this.sortPuzzles(this.puzzleProducts);
    }
  },
  created: function(){
  },
  mounted: function(){
    this.localPuzzleProducts = this.sortPuzzles(this.puzzleProducts);
    this.localWishlistProductIds = this.wishlistProductIds;
    this.filterProducts();
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
      activeSubscriptionId: 'getActiveSubscriptionId',
      puzzleProducts: 'getPuzzleProducts',
      wishlistProductIds: 'getWishlistProductIds',
      brands: 'getBrands',
      themesByProduct: 'getPuzzleProductThemesByProduct',
      themes: 'getThemes'
    })
  },
  data: function(){
    return {
      largePiece: false,
      standardPiece: false,
      hardcodedBrands: [
        { id: 41, name: "Art & Fable Puzzle Company"},
        { id: 44, name: "BetterCo."},
        { id: 37, name: "Cobble Hill"},
        { id: 52, name: "Cloudberries"},
        { id: 19, name: "Dowdle"},
        { id: 36, name: "eeBoo"},
        { id: 13, name: "Eurographics"},
        { id: 20, name: "Flame Tree Publishing"},
        { id: 45, name: "Fred"},
        { id: 4, name: "Galison"},
        { id: 57, name: "Happily"},
        { id: 51, name: "Jigsaw Nomad"},
        { id: 54, name: "LePuzz"},
        { id: 49, name: "Lucky Puzzles"},
        { id: 48, name: "MintyFizz Puzzles"},
        { id: 47, name: "Moondog Puzzles"},
        { id: 22, name: "Mudpuppy"},
        { id: 11, name: "New York Puzzle Company"},
        { id: 12, name: "Peter Pauper Press"},
        { id: 59, name: "Pezel"},
        { id: 58, name: "Playful Pastimes"},
        { id: 9, name: "Pomegranate"},
        { id: 40, name: "puzz"},
        { id: 46, name: "Puzzlebilities"},
        { id: 53, name: "Puzzlefolk"},
        { id: 6, name: "Ravensburger"},
        { id: 56, name: "Unified Pieces"},
        { id: 32, name: "Areaware"},
        { id: 10, name: "Bits And Pieces"},
        { id: 7, name: "Buffalo Puzzles"},
        { id: 1, name: "Ceaco"},
        { id: 3, name: "MasterPieces"},
        { id: 2, name: "SunsOut"},
      ],
      drawer: false,
      localWishlistProductIds: [],
      brandFilters: [],
      localPuzzleProducts: [],
      sortItems: ["Newest", "Alphabetical", "Piece Count "],
      pieceCounts: [2000, 1500, 1000, 750, 550, 500, 350, 300, 250],
      selectedPieceCounts: [],
      selectedThemes: [],
      themeColors: {
        landscape: {
          hex: "#FFCDD2",
          color: "red"
        },
        gradient: {
          color: "purple",
          hex: "#E1BEE7"
        },
        fantasy: {
          color: "deep-purple",
          hex: "#D1C4E9"
        },
        disney: {
          color: "cyan",
          hex: "#B2EBF2"
        },
        destination: {
          color: "teal",
          hex: "#B2DFDB"
        },
        finearts: {
          color: "blue-grey",
          hex: "#CFD8DC"
        },
        collage: {
          color: "blue",
          hex: "#90CAF9"
        },
        animals: {
          color: "light-blue",
          hex: "#B3E5FC"
        },
        americana: {
          color: "amber",
          hex: "#FFECB3"
        },
        holiday: {
          color: "green",
          hex: "#C8E6C9"
        },
      }
    }
  },
}
</script>
<style scoped>
.title-bg {
  background-color: rgba(0,0,0,0.2);
}
.card-subtitle {
  margin-bottom: 52px;
}
.bottom-actions{
  position: absolute;
  bottom: 0;
}
</style>
<style>
.theme {
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 4px;
  margin-top: 4px;
  display: inline-block;
}

.theme-landscape {
  background-color: #FFCDD2;
}
.theme-gradient {
  background-color: #E1BEE7;
}
.theme-fantasy {
  background-color: #D1C4E9;
}
.theme-disney {
  background-color: #B2EBF2;
}
.theme-destination {
  background-color: #B2DFDB;
}
.theme-collage {
  background-color: #90CAF9;
}
.theme-fine {
  background-color: #C8E6C9;
}
.theme-animals {
  background-color: #B3E5FC;
}
.theme-americana {
  background-color: #FFECB3;
}
.theme-holiday {
  background-color: #CFD8DC;
}
</style>
