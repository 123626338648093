// initial state
import * as types from "../mutation-types";

const state = {
  error_message: null,
  success_message: null
}

// getters
const getters = {
}

const actions = {

}

// mutations
const mutations = {
  [types.SUCCESS] (state, message ) {
    state.success_message = message
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}