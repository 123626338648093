<template>
  <v-card class="feature-highlight">
    <v-card-text class="d-flex">

      <img src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/alania_cutout_happy_small.png" class="negative-margin" />
      <div class="pl-md-3">
        <h2>Ready for your next pack?</h2>
        <p>Get your next pack shipped out the next business day. Click the accelerate button for more details.</p>
        <v-dialog
            v-model="dialog"
            width="100%"
            max-width="400">
          <template v-slot:activator="{ on, attrs }" >
            <v-btn class="blue white--text" v-bind="attrs" large
                   v-on="on">Accelerate</v-btn>
          </template>

          <v-card>
            <v-container>
              <v-row align-content="center" justify="center">
                <v-col class="text-center pa-6" cols="12">

                  <p class="mt-2 font-weight-bold">
                    Accelerating your puzzle
                  </p>

                  <div v-if="subscriptionStatus.is_eligible_to_accelerate">
                    <p v-if="subscriptionStatus.days_left_before_next_shipment == 0">
                      Accelerating your puzzles will move your renewal date to today and your puzzles will get shipped the next business day. Your portal account will be updated then. Click below to confirm
                    </p>
                    <p v-if="subscriptionStatus.days_left_before_next_shipment != 0">
                      Accelerating your puzzles will adjust your renewal date by {{subscriptionStatus.days_left_before_next_shipment}} days and your puzzles will get shipped the next business day. Your portal account will be updated then. Click below to confirm
                    </p>
                  </div>
                  <div v-if="!subscriptionStatus.is_eligible_to_accelerate">
                    <p>
                      You are currently not eligible to accelerate. You are allowed two packs out at a time and your account must be active. If you feel this is our error, please reach out to support and someone will help.
                    </p>
                  </div>

                  <v-btn class="blue text--white" @click="confirmAcceleration" v-if="subscriptionStatus.is_eligible_to_accelerate">Confirm Acceleration</v-btn>
                  <v-btn class="red text--white" @click="dialog = false">Cancel</v-btn>


                </v-col>
              </v-row>
            </v-container>

          </v-card>
        </v-dialog>
      </div>
    </v-card-text>




  </v-card>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'accelerate',
  components: {
  },
  computed: {
    ...mapState({
    }),

    ...mapGetters({
      subscriptionStatus: 'getSubscriptionStatus',
    }),
  },
  methods: {
    confirmAcceleration: function(){
      this.dialog = false;
      this.$store.dispatch("accelerateNextPack");
    }

  },
  data: function(){
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.pointer-hover {
  cursor: pointer;
}
.feature-highlight {
  background-color: #FBE639 !important;
}
.feature-highlight > .v-card__text {
  color: rgba(0,0,0,.87) !important;
}
.negative-margin {
  margin: -16px;
  margin-right: 0px;
}
</style>
