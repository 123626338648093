// initial state
import * as types from "../mutation-types";

const state = []

// getters
const getters = {
}

const actions = {

}

// mutations
const mutations = {
  [types.SET_PUZZLES] (state, puzzles ) {
    this.state.puzzles = puzzles
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}