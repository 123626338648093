/* eslint no-console: 0 */
// Run this example by adding <%= javascript_pack_tag 'hello_vue' %> (and
// <%= stylesheet_pack_tag 'hello_vue' %> if you have styles in your component)
// to the head of your layout file,
// like app/views/layouts/application.html.erb.
// All it does is render <div>Hello Vue</div> at the bottom of the page.

import Vue from 'vue'
import App from '../app.vue'
import Vuetify from 'vuetify'
import store from './portal/store'
import router from './portal/router'
import axios from 'axios'
import 'vuetify/dist/vuetify.min.css'
import "../stylesheets/portal";


Vue.use(Vuetify)

Vue.config.productionTip = false
global.router = router

let instance = axios.create();
instance.interceptors.request.use(function (config) {
  // Do something before request is sent
    config.headers = {'Content-Type': 'application/vnd.api+json',
      'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content')}
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

global.axios = instance
var url = new URL(window.location.href);
global.customer_id = url.searchParams.get("customer_id");
document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
    vuetify: new Vuetify(),
    store,
    router,
    render: h => h(App),
  }).$mount()
  document.body.appendChild(app.$el)

  console.log(app)
})
