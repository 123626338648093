import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
// initial state
const state = []

// getters
const getters = {
  getPuzzleProductThemes: (state) => {
    return state
  },
  getPuzzleProductThemesByProduct: (state) => {
    let byProduct = new Object();
    Object.values(state).forEach(function(obj) {
      if (!byProduct[obj.attributes.puzzleProductId]){
        byProduct[obj.attributes.puzzleProductId] = new Array();
      }
      byProduct[obj.attributes.puzzleProductId].push(obj.attributes.themeId);
    });
    return byProduct;
  },
}

const actions = {
  getPuzzleProductThemes(store) {
    store.commit(types.GET_PUZZLE_PRODUCT_THEMES);
    subscriptionService.getPuzzleProductThemes(data => {
      store.commit(types.SET_PUZZLE_PRODUCT_THEMES, data.puzzleProductThemes);
    })
  },
}

// mutations
const mutations = {
  [types.GET_PUZZLE_PRODUCT_THEMES] (state) {

  },
  [types.SET_PUZZLE_PRODUCT_THEMES] (state, puzzleProductThemes ) {
    this.state.puzzleProductThemes = puzzleProductThemes;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}