<template>
  <div class="d-flex justify-center align-center splash">
    <v-card class="pa-6 login-card ma-6">
      <div class="text-center">
        <img src="/portal/ctp-logo.png" class="logo" />
        <h2 class="mt-2">Reset your account password</h2>
        <p>Enter a new password</p>
      </div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="password"
            label="password"
            name="password"
            type="password"
            required
            v-on:keyup.enter="resetPassword"
        ></v-text-field>


        <v-btn
            color="success"
            class="mb-4"
            @click="resetPassword"
        >
          Reset Password
        </v-btn>


      </v-form>
      <router-link :to="{ name: 'login'}">Return to login</router-link>
      <p class="mt-4 text-center">

        Having problems logging in? <br/>
        Click <a @click="openFreshdesk">here</a> to get help from support
      </p>
    </v-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
export default {
  name: 'reset_password',
  components: {
  },
  methods: {
    openFreshdesk: function(){
      FreshworksWidget('open', 'ticketForm');
    },
    resetPassword: function(){
      this.$store.dispatch("resetPassword", {password: this.password, url: this.$route.query.reset_url})
    }
  },
  computed: {
    ...mapGetters({
    }),
    ...mapState({
    }),
  },
  data: function(){
    return {
      'password': ''
    }
  }
}
</script>