import normalize from 'json-api-normalizer';
export default {
  getCustomer(cb){
    axios.get('/api/v1/customer.json?include=subscriptions.plan,subscriptions.level')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getLevels(cb){
    axios.get('/api/v1/levels.json')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getBrands(cb) {
    axios.get('/api/v1/brands.json')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getPuzzleProductThemes(cb){
    axios.get('/api/v1/puzzle-product-themes.json?')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getSubscriptionThemes(subscriptionId, cb){
    axios.get('/api/v1/subscription-themes.json?filter[subscription-id]='+subscriptionId)
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  updateSubscriptionThemes(subscriptionId, themes, cb){
    axios.put('/api/v1/subscriptions/'+subscriptionId+'/update_themes.json', {
      themes: themes
    })
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getThemes(cb){
    axios.get('/api/v1/themes.json')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getSubscriptionData(subscriptionId, cb){
    axios.get('/api/v1/subscriptions/'+subscriptionId+'.json?include=puzzle-orders.puzzle,puzzle-orders.shipment.return-shipments,shipping-address,wishlist-puzzle-products')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },

  updateShippingAddress(store, shippingAddress, cb){
    const vm = store;

  },
  updateLevel(data, cb) {
    axios.put('/api/v1/subscriptions/'+data.subscription_id+'.json', {
      data: {
        type: 'subscriptions',
        id: data.subscription_id ,
        attributes: {
          "level-id": data.level_id
        }
      }
      })
      .then(response => {cb(normalize(response.data))})
      .catch(e => {
      })
  },
  getPuzzleProducts(cb) {
    axios.get('/api/v1/puzzle-products.json')
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  getWishlist(subscriptionId, cb) {
    axios.get('/api/v1/wishlist-puzzle-products.json?filter[subscription-id]='+subscriptionId)
      .then(response => cb(normalize(response.data)))
      .catch(e => {
        console.log(e)
      })
  },
  addWishlist(subscriptionId, productId, cb) {
    axios.post('/api/v1/wishlist-puzzle-products.json', {
      data: {
        type: 'wishlist-puzzle-products',
        attributes: {
          "subscription-id": subscriptionId,
          "puzzle-product-id": productId
        }
      }
    })
      .then(response => {cb(normalize(response.data))})
      .catch(e => {
      })
  },
  removeWishlist(id, cb) {
    axios.delete('/api/v1/wishlist-puzzle-products/'+id+'.json', {
      data: {
        type: 'wishlist-puzzle-products',
        id: id,
        attributes: {
          "id": id,
        }
      }
    })
      .then(response => {cb(normalize(response.data))})
      .catch(e => {
      })
  },
  manuallyCreatePuzzleOrders(subscriptionId, cb) {
    axios.post('/api/v1/subscriptions/'+subscriptionId+'/manually_create_puzzle_orders.json', {

    })
      .then(response => {
        cb(normalize(response.data))
      })
      .catch(e => {
      })
  },
  accelerateNextPack(subscriptionId, cb) {
    axios.post('/api/v1/subscriptions/'+subscriptionId+'/accelerate_next_pack.json', {

    })
      .then(response => {
        cb(response.data)
      })
      .catch(e => {
      })
  },
  getSubscriptionStatus(subscriptionId, cb) {
    axios.get('/api/v1/subscriptions/'+subscriptionId+'/status.json', {

    })
      .then(response => {
        cb(response.data)
      })
      .catch(e => {
      })
  }

}
