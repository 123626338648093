<template>
  <div>
    <v-container>
      <div v-if="activeSubscriptionId == null">
        <v-skeleton-loader></v-skeleton-loader>
      </div>
    </v-container>
    <div v-if="activeSubscriptionId != null">
      <v-container>
        <CurrentPuzzleStatus></CurrentPuzzleStatus>
      </v-container>
      <v-container v-if="hasAccelerateOption">
        <Accelerate></Accelerate>
      </v-container>
      <v-container v-if="hasTwoPuzzleOffer && !hasAccelerateOption">
        <TwoPuzzleOffer></TwoPuzzleOffer>
      </v-container>
      <v-container v-if="activeSubscription.attributes.canceledAt">
        <SubscriptionStatus></SubscriptionStatus>
      </v-container>
      <v-container >
        <v-row class="justify-center">
          <v-col sm="12" md="4">
            <Themes></Themes>
          </v-col>
          <v-col sm="12" md="4">
            <Level></Level>
          </v-col>
          <v-col  sm="12" md="4">
            <ShippingAddress></ShippingAddress>
          </v-col>
        </v-row>
        <v-row class="justify-center">
          <v-col sm="12" md="4">
            <Account></Account>
          </v-col>
          <v-col sm="12" md="4">
            <Buyback></Buyback>
          </v-col>
          <v-col sm="12" md="4">
            <Help></Help>
          </v-col>
        </v-row>
      </v-container>
      <v-container>
        <PuzzleOrders></PuzzleOrders>
      </v-container>
    </div>
  </div>
</template>

<script>
import CurrentPuzzleStatus from './dashboard/current_puzzle_status'
import SubscriptionStatus from './dashboard/subscription_status'
import PuzzleOrders from './dashboard/puzzle_orders'
import Buyback from './dashboard/buyback'
import Account from './dashboard/account'
import Help from './dashboard/help'
import Themes from './dashboard/themes'
import Level from './dashboard/level'
import ShippingAddress from './dashboard/shipping_address'
import TwoPuzzleOffer from './dashboard/two_puzzle_offer'
import Accelerate from './dashboard/accelerate'
import {mapGetters, mapState} from "vuex";
export default {
  name: 'subscription',
  components: {
    Account, SubscriptionStatus, PuzzleOrders, Buyback, Help, Themes, Level, ShippingAddress, CurrentPuzzleStatus, TwoPuzzleOffer, Accelerate
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      activeSubscriptionId: 'getActiveSubscriptionId',
      activeSubscription: 'getActiveSubscription'
    }),
    ...mapState({
      plans: 'plans',
    }),
    hasTwoPuzzleOffer: function () {
      if (this.activeSubscription.attributes.canceledAt) {
        return false;
      } else if (this.plans[this.activeSubscription.attributes.planId].attributes.number_of_puzzles == 2) {
        return false;
      } else {
        return true;
      }

    },
    hasAccelerateOption: function () {
      return false;// this.plans[this.activeSubscription.attributes.planId].attributes.orderCreationIntervalDays;
    },
  },
  mounted: function(){
  },
  data: function(){
    return {
    }
  }
}
</script>
