import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
// initial state
const state = []

// getters
const getters = {
  getBrands: (state) => {
    return state
  },
}

const actions = {
  getBrands(store) {
    store.commit(types.GET_BRANDS);
    subscriptionService.getBrands(data => {
      store.commit(types.SET_BRANDS, data.brands);
    })
  },
}

// mutations
const mutations = {
  [types.GET_BRANDS] (state) {

  },
  [types.SET_BRANDS] (state, brands ) {
    this.state.brands = brands;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}