import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
import normalize from "json-api-normalizer";
// initial state
const state = []

// getters
const getters = {
  getWishlistProductIds: (state) => {
    if (state){
      return Object.values(state).map( w => w.attributes.puzzleProductId)
    } else {
      return []
    }
  },
  getWishlistProducts: (state) => {
    return state
  },
}

const actions = {
  getWishlist(store, subscriptionId) {
    store.commit(types.GET_WISHLIST);
    subscriptionService.getWishlist(subscriptionId, data => {
      store.commit(types.SET_WISHLIST, data.wishlistPuzzleProducts || []);
    })
  },
  addWishlist(store, productId) {

    axios.post('/api/v1/wishlist-puzzle-products.json', {
      data: {
        type: 'wishlist-puzzle-products',
        attributes: {
          "subscription-id": store.getters.getActiveSubscription.id,
          "puzzle-product-id": productId
        }
      }
    })
      .then(response => {
        let data = normalize(response.data);
        store.commit(types.WISHLIST_ADDED, data);

      })
      .catch(e => {
      })
  },
  removeWishlist(store, productId) {
    let id = 0;
    Object.values(this.state.wishlist).forEach( (wishlist) => {
      if (wishlist.attributes.puzzleProductId == productId) {
        id = wishlist.id;
      }
    })
    subscriptionService.removeWishlist(id, data => {
      store.commit(types.WISHLIST_REMOVED, id);
    })
  }
}

// mutations
const mutations = {
  [types.GET_WISHLIST] (state) {

  },
  [types.WISHLIST_ADDED] (state, data) {
    let vals = Object.assign({}, this.state.wishlist, data.wishlistPuzzleProducts);
    this.state.wishlist = vals;
  },
  [types.WISHLIST_REMOVED] (state, id) {
    delete this.state.wishlist[id]
  },
  [types.SET_WISHLIST] (state, wishlistPuzzleProducts ) {
    this.state.wishlist = wishlistPuzzleProducts;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}