import Vue from 'vue'
import Vuex from 'vuex'
import levels from './modules/levels'
import themes from './modules/themes'
import notifications from './modules/notifications'
import subscriptions from './modules/subscriptions'
import subscriptionThemes from './modules/subscription_themes'
import shippingAddress from './modules/shipping_address'
import puzzleOrders from './modules/puzzle_orders'
import puzzleProducts from './modules/puzzle_products'
import puzzles from './modules/puzzles'
import shipments from './modules/shipments'
import returnShipments from './modules/return_shipments'
import wishlist from './modules/wishlist'
import plans from './modules/plans'
import customer from './modules/customer'
import brands from './modules/brands'
import puzzleProductThemes from './modules/puzzle_product_themes'
import subscriptionStatus from './modules/subscription_status'
import actions from './actions'
import mutations from './mutations'
import createPersistedState from 'vuex-persistedstate'


Vue.use(Vuex)

let store =  new Vuex.Store({
    modules: {
        levels,
        plans
    },
    actions,
    mutations,
    plugins: [],
    strict: false
})
store.registerModule('puzzleProductThemes', puzzleProductThemes, {preserveState: false})
store.registerModule('brands', brands, {preserveState: false})
store.registerModule('puzzles', puzzles, {preserveState: false})
store.registerModule('puzzleProducts', puzzleProducts, {preserveState: false})
store.registerModule('shipments', shipments, {preserveState: false})
store.registerModule('returnShipments', returnShipments, {preserveState: false})
store.registerModule('themes', themes, {preserveState: false})
store.registerModule('puzzleOrders', puzzleOrders, {preserveState: false})
store.registerModule('subscriptionThemes', subscriptionThemes, {preserveState: false})
store.registerModule('notifications', notifications, {preserveState: false})
store.registerModule('shippingAddress', shippingAddress, {preserveState: false})
store.registerModule('customer', customer, {preserveState: false})
store.registerModule('subscriptions', subscriptions, {preserveState: false})
store.registerModule('wishlist', wishlist, {preserveState: false})
store.registerModule('subscriptionStatus', subscriptionStatus, {preserveState: false})
export default store