import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
// initial state
const state = []


const actions = {
  getLevels(store) {
    store.commit(types.GET_LEVELS);
    subscriptionService.getLevels(data => {
      store.commit(types.SET_LEVELS, data.levels);
    })
  },
  updateLevel(store, levelId) {
    subscriptionService.updateLevel({subscription_id: store.getters.getActiveSubscription.id, level_id: levelId},
        data => {
      store.commit(types.SUCCESS, "Your level is updated");
      router.back();
      let key = Object.keys(data.subscriptions)[0];
      //todo: move to mutation
      store.rootState.subscriptions.subscriptions[key].attributes.levelId = data.subscriptions[key].attributes.levelId;
    })
  }
}

// mutations
const mutations = {

  [types.SET_LEVELS] (state, levels ) {
    this.state.levels = levels;
  },
  [types.GET_LEVELS] (state, levels ) {
  },

}

export default {
  state,
  actions,
  mutations
}