<template>
  <v-card class="account-card">
    <v-card-title>
      need help?
    </v-card-title>
    <v-card-subtitle>
      Contact us below for any puzzling questions
    </v-card-subtitle>
    <v-img contain max-height="150px" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/help.png"></v-img>
    <div class="card-spacer"></div>
    <v-card-actions>
      <v-btn class="green white--text" @click="openFreshdesk">Contact Support</v-btn>
    </v-card-actions>
 </v-card>
</template>

<script>
  export default {
    name: 'help',
    components: {
    },
    methods: {
      openFreshdesk: function(){
        FreshworksWidget('open', 'ticketForm');
      }
    },
    data: function(){
      return {
      }
    }
  }
</script>
