import * as types from './mutation-types'

export default {
  [types.GET_PUZZLE_ORDERS] (state) {

  },
  [types.GET_SHIPPING_ADDRESS] (state ) {
  },
  [types.UPDATE_SHIPPING_ADDRESS] (state, shipping_address ) {
  },
  [types.ERROR] (state, message ) {
    state.notifications.error_message = message
  },

}