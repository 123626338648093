export const LOADING = 'LOADING'
export const LOADED = 'LOADED'
export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const GET_SUBSCRIPTIONS = "GET_SUBSCRIPTIONS"
export const SET_SUBSCRIPTIONS = "SET_SUBSCRIPTIONS"
export const GET_SUBSCRIPTION = "GET_SUBSCRIPTION"
export const SET_SUBSCRIPTION = "SET_SUBSCRIPTION"
export const GET_PUZZLE_ORDERS = "GET_PUZZLE_ORDERS"
export const SET_PUZZLE_ORDERS = "SET_PUZZLE_ORDERS"
export const UPDATE_PUZZLE_ORDERS = "UPDATE_PUZZLE_ORDERS"
export const GET_SHIPPING_ADDRESS = "GET_SHIPPING_ADDRESS"
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS"
export const UPDATE_SHIPPING_ADDRESS = "UPDATE_SHIPPING_ADDRESS"
export const SHIPPING_ADDRESS_UPDATED = "SHIPPING_ADDRESS_UPDATED"
export const SET_LEVELS = "SET_LEVELS"
export const GET_LEVELS = "GET_LEVELS"
export const UPDATE_LEVEL = "UPDATE_LEVEL"
export const GET_CUSTOMER = "GET_CUSTOMER"
export const SET_CUSTOMER = "SET_CUSTOMER"
export const SET_PLANS = "SET_PLANS"
export const GET_THEMES = "GET_THEMES"
export const SET_THEMES = "SET_THEMES"
export const GET_SUBSCRIPTION_THEMES = "GET_SUBSCRIPTION_THEMES"
export const SET_SUBSCRIPTION_THEMES = "SET_SUBSCRIPTION_THEMES"
export const SET_PUZZLES = "SET_PUZZLES"
export const GET_PUZZLE_PRODUCTS = "GET_PUZZLE_PRODUCTS"
export const SET_PUZZLE_PRODUCTS = "SET_PUZZLE_PRODUCTS"
export const SET_SHIPMENTS = "SET_SHIPMENTS"
export const SET_RETURN_SHIPMENTS = "SET_RETURN_SHIPMENTS"
export const GET_WISHLIST = "GET_WISHLIST"
export const SET_WISHLIST = "SET_WISHLIST"
export const WISHLIST_ADDED = "WISHLIST_ADDED"
export const WISHLIST_REMOVED = "WISHLIST_REMOVED"
export const GET_BRANDS = "GET_BRANDS"
export const SET_BRANDS = "SET_BRANDS"
export const GET_PUZZLE_PRODUCT_THEMES = "GET_PUZZLE_PRODUCT_THEMES"
export const SET_PUZZLE_PRODUCT_THEMES = "SET_PUZZLE_PRODUCT_THEMES"
export const LOGIN = "LOGIN"
export const LOGGED_IN = "LOGGED_IN"
export const SET_SUBSCRIPTION_STATUS = "SET_SUBSCRIPTION_STATUS"
export const SET_ACCELERATION = "SET_ACCELERATION"