// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";

const state = {
  subscriptions: [],
  activeSubscriptionId: null
}

// getters
const getters = {
  getActiveSubscriptionId: (state) => {
    return state.activeSubscriptionId
  },
  subscriptionsArray: (state) => {return Object.values(state.subscriptions).map( sub => sub)},
  getActiveSubscription: (state) => {
    if (state.activeSubscriptionId) {
      return state.subscriptions[state.activeSubscriptionId]
    } else {
      return { id: null }
    }

  }
}

const actions = {
  getSubscriptionData(store, subscriptionId) {
    store.commit(types.SET_SUBSCRIPTION, null);
    subscriptionService.getSubscriptionData(subscriptionId, data => {
      store.commit(types.SET_SHIPPING_ADDRESS, data.shippingAddresses);
      store.commit(types.SET_PUZZLES, data.puzzles);
      store.commit(types.SET_SHIPMENTS, data.shipments);
      store.commit(types.SET_RETURN_SHIPMENTS, data.returnShipments);
      store.commit(types.SET_PUZZLE_ORDERS, data.puzzleOrders);
      store.commit(types.SET_SUBSCRIPTION, subscriptionId);
      store.commit(types.SET_WISHLIST, data.wishlistPuzzleProducts);
    })
  },
  manuallyCreatePuzzleOrders(store){
    subscriptionService.manuallyCreatePuzzleOrders(store.state.activeSubscriptionId, data => {
      store.commit(types.UPDATE_PUZZLE_ORDERS, data.puzzleOrders);
    })
  },
  accelerateNextPack(store){
    subscriptionService.accelerateNextPack(store.state.activeSubscriptionId, data => {
      store.commit(types.SET_ACCELERATION);
      store.commit(types.SUCCESS, data.message);
    })
  },

}

// mutations
const mutations = {
  [types.GET_SUBSCRIPTIONS] (state) {

  },
  [types.GET_SUBSCRIPTION] (state, subscriptionId) {
  },
  [types.SET_SUBSCRIPTIONS] (state, subscriptions ) {
    state.subscriptions = subscriptions
  },
  [types.SET_SUBSCRIPTION] (state, subscriptionId ) {
    state.activeSubscriptionId = subscriptionId;
    if (subscriptionId) {
      this.dispatch("getSubscriptionStatus");
    }
  },
  [types.SET_ACCELERATION] (state, response ) {
    this.dispatch("getSubscriptionStatus");
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}