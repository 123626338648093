<template>
  <div class="d-flex justify-center align-center splash">
    <v-card class="pa-6 login-card ma-6">
      <div class="text-center">
        <img src="/portal/ctp-logo.png" class="logo" />
        <h2 class="mt-2">Sign In</h2>
      </div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          @submit="login()"
          autocomplete="on"
      >
        <v-text-field
            v-model="email"
            label="Email"
            name="email"
            required
            autocomplete="email"
            autocorrect="off"
            autocapitalize="off"
            v-on:keyup.enter="login"
        ></v-text-field>

        <v-text-field
            v-model="password"
            label="password"
            required
            type="password"
            name="password"
            v-on:keyup.enter="login"
        ></v-text-field>

        <v-btn
            color="success"
            class="mb-4"
            @click="login"
        >
          Login
        </v-btn>


      </v-form>
      <router-link v-bind:to="'forgot_password'">Forgot your password?</router-link>
      <p>
        <router-link v-bind:to="'activate_account'">Need to activate your account?</router-link>
      </p>
      <p class="mt-4 text-center">

        Having problems signing in? <br/>
        Click <a @click="openFreshdesk">here</a> to get help from support
      </p>

    </v-card>
  </div>
</template>

<script>
import TwoPuzzleOffer from './dashboard/two_puzzle_offer'
import {mapGetters, mapState} from "vuex";
export default {
  name: 'login',
  components: {
    TwoPuzzleOffer
  },
  methods: {
    openFreshdesk: function(){
      FreshworksWidget('open', 'ticketForm');
    },
    login: function(){
      this.$store.dispatch("login", {email: this.email, password: this.password})
    }
  },
  computed: {
    ...mapGetters({
    }),
    ...mapState({
    }),
    hasTwoPuzzleOffer: function() {
    }
  },
  data: function(){
    return {
      'email': '',
      'password': ''
    }
  }
}
</script>
<style>
.login-card {
  width: 600px;
}
.logo {
  max-width: 250px;
  width: 100%
}
</style>