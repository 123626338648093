<template>
  <div>
    <v-skeleton-loader v-if="activeSubscription == null"
        type="article, actions"
    ></v-skeleton-loader>


    <v-card v-if="activeSubscription != null">
      <v-card-title>Current Plan: {{getPlanName(activeSubscription.attributes.planId)}} </v-card-title>
      <v-card-subtitle >Current status: {{getSubscriptionStatus()}}</v-card-subtitle>
      <v-card-actions>
        <v-btn v-if="activeSubscription.attributes.canceledAt != null"
            class="blue white--text" @click="reactivate">
          Reactivate subscription
        </v-btn>
        <v-btn v-if="activeSubscription.attributes.canceledAt == null"
            class="blue white--text" @click="pause">
          Pause subscription
        </v-btn>
        <v-btn v-if="activeSubscription.attributes.canceledAt == null" @click="account">
          cancel
        </v-btn>
      </v-card-actions>
    </v-card>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";

export default {
  name: 'subscription_status',
  components: {
  },
  methods: {
    account: function() {
      window.top.location.href= "https://completingthepuzzle.com/tools/recurring/get-subscription-access?passwordless=true";
    },
    getPlanName: function(planId) {
      return this.plans[planId].attributes.planName;
    },
    getSubscriptionStatus: function(){
      if (this.activeSubscription.attributes.canceledAt == null) {
        return "Active";
      } else {
        let date = new Date(this.activeSubscription.attributes.membershipEndDate);
        return "Cancelled (membership end date: "+date.toLocaleDateString()+")";
      }
    },
    pause: function() {
      FreshworksWidget('identify', 'ticketForm', {
        subject: "Hi - I'd like to pause my subscription",
        description: "Effective <date>, can I pause my subscription and resume it on <date>. Thank you!"
      });
      FreshworksWidget('open', 'ticketForm');
    },
    reactivate: function() {
      FreshworksWidget('identify', 'ticketForm', {
        subject: "Hi - I'd like to reactivate my subscription",
        description: "Can you reactivate my subscription, "+ this.plans[this.activeSubscription.attributes.planId].attributes.planName + ". Thank you!"
      });
      FreshworksWidget('open', 'ticketForm');
    }
  },
  computed: {
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
    }),
    ...mapState({
      plans: 'plans'
    }),
  },
  data: function(){
    return {
    }
  },
}
</script>
