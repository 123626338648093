<template>
  <div>
    <v-skeleton-loader v-if="activeSubscription == null"
        type="article, actions"
    ></v-skeleton-loader>
    <v-card v-if="activeSubscription != null">
      <v-card-title>Current Puzzle</v-card-title>
      <div v-for="puzzleOrder in currentOrders" :key="puzzleOrder.id">
        <v-card-subtitle >
          Puzzle: {{puzzleProducts[puzzles[puzzleOrder.attributes.puzzleId].attributes.puzzleProductId].attributes.name}}
        </v-card-subtitle>
        <v-card-text v-if="puzzleOrder.attributes.shipmentId">
          <b>Tracking Information</b>
          <div>
            Status: {{shipments[puzzleOrder.attributes.shipmentId].attributes.shippingTrackingStatus}}
          </div>
          <div >
            Tracking code:
            <a v-if="puzzleOrder.attributes.shipmentId" :href="'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1='+shipments[puzzleOrder.attributes.shipmentId].attributes.shippingTrackingCode">{{shipments[puzzleOrder.attributes.shipmentId].attributes.shippingTrackingCode}}</a>
          </div>
          <br />
          <b>Return Information</b>
          <div>
            Tracking code:
            <a :href="'https://tools.usps.com/go/TrackConfirmAction?qtc_tLabels1='+returnShipments[puzzleOrder.attributes.returnShipmentId].attributes.shippingTrackingCode">{{returnShipments[puzzleOrder.attributes.returnShipmentId].attributes.shippingTrackingCode}}</a>
          </div>
          Missing your return label? Find it <a :href="returnShipments[puzzleOrder.attributes.returnShipmentId].attributes.shippingLabelUrl">here</a>
        </v-card-text>
        <v-card-text v-if="!puzzleOrder.attributes.shipmentId">
          Your puzzle is being packed and will be shipped the following business day.
        </v-card-text>
      </div>
      <v-card-text v-if="currentOrders.length ==0 && shouldPuzzlesAutoShip">
        Your puzzle is being selected. Check back soon for updates!
      </v-card-text>

      <v-card-text v-if="!shouldPuzzlesAutoShip">
        <ManuallyShipPuzzle></ManuallyShipPuzzle>
      </v-card-text>



      <v-card-title>Current Shipping Address</v-card-title>
      <v-card-subtitle>
        {{shippingAddress.attributes.street1}}<br />
        {{shippingAddress.attributes.street2}}<br v-if="shippingAddress.attributes.street2" />
        {{shippingAddress.attributes.city}}, {{shippingAddress.attributes.state}} {{shippingAddress.attributes.zip}}
      </v-card-subtitle>
    </v-card>

  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
import ManuallyShipPuzzle from './manually_ship_puzzle';

export default {
  name: 'current_puzzle_status',
  components: {
    ManuallyShipPuzzle
  },
  computed: {
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
      currentOrders: 'getCurrentOrders',
      shippingAddress: 'getShippingAddress'
    }),
    ...mapState({
      shipments: 'shipments',
      returnShipments: 'returnShipments',
      puzzleProducts: 'puzzleProducts',
      puzzles: 'puzzles',
      plans: 'plans'
    }),
    getPuzzleName: function(puzzleId) {
    },
    shouldPuzzlesAutoShip: function(){
      return this.plans[this.activeSubscription.attributes.planId].attributes.shouldAutoCreateOrders;
    }
  },
  methods: {
    toggleVisible: function(e){
      this.currentTarget('blurry-text')
    },
    getShipmentStatus: function(){
      return shipments[currentOrder.attributes.shipmentId].attributes.shippingTrackingStatus;
    },


  },
  data: function(){
    return {
    }
  },
}
</script>

<style>
  .blurry-text {
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
  }
</style>
