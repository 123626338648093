<template>
  <v-card class="account-card">
    <v-card-title>
      Puzzle History
    </v-card-title>
    <v-card-text class="py-0">
      <v-timeline
          align-top
          dense
      >
        <v-timeline-item v-for="(puzzleOrder, id) in puzzleOrders" :key="puzzleOrder.id" v-if="puzzleOrder.attributes.puzzleId" icon="mdi-puzzle">
          <v-row class="pt-1">
            <v-col>
              <strong>{{getPuzzleName(puzzleOrder.attributes.puzzleId)}}</strong>
              <p>{{getOrderDate(puzzleOrder)}}</p>
              <v-img
                  v-bind:src="getPuzzleImageSrc(puzzleOrder.attributes.puzzleId)"
                  max-width="200"
                  class="grey darken-4"
              ></v-img>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { mapState } from 'vuex'
export default {
  name: 'puzzle_orders',
  components: {
  },
  computed: {
    ...mapGetters({
      puzzleOrders: 'getPuzzleOrders'
    }),
    ...mapState({
      puzzles: 'puzzles',
      puzzleProducts: 'puzzleProducts'
    }),
  },
  created: function(){
  },
  methods: {
    getOrderDate: function(puzzleOrder){
      let d = new Date(puzzleOrder.attributes.createdAt);
      return d.toLocaleDateString();
    },
    getPuzzleName: function(puzzleId){
      return this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.name;
    },
    getPuzzleImageSrc: function(puzzleId){
      return this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.mediumImageSrc;
    }
  },
  data: function(){
    return {
    }
  }
}
</script>
