<template>
  <div>
    <v-container v-if="hasMultiple">
      <p class="text-center">You have multiple subscriptions, click below to view details of each</p>
      <v-tabs centered>
        <v-tab v-for="subscription in subscriptions" :to="{path: '/portal/subscriptions/'+subscription.id+'/home'}" >{{getName(subscription.attributes.planId)}}</v-tab>
      </v-tabs>
    </v-container>
    <transition name="fade">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { mapGetters } from 'vuex'
import SubscriptionComponent from './dashboard/subscription.vue'
export default {
  name: 'subscriptions',
  components: {
    SubscriptionComponent
  },
  computed: {
    ...mapState({
      plans: 'plans'
    }),
    ...mapGetters({
      subscriptions: 'subscriptionsArray',
      activeSubscriptionId: 'getActiveSubscriptionId'
    }),
    hasMultiple: function() {
      return this.subscriptions.length > 1
    }
  },
  mounted: function(){
  },
  methods: {
    getName: function(planId) {
      return this.plans[planId].attributes.planName;
    },
  },
  data: function(){
    return {
    }
  }
}
</script>
