<template>
  <v-card class="account-card">
    <v-card-title>
      Puzzle Buyback
    </v-card-title>
    <v-card-subtitle>
      We'll buy your unused puzzles from you! Restrictions apply, see details <a href="https://www.completingthepuzzle.com/puzzle-buy-back-program">here</a>
    </v-card-subtitle>
    <v-img contain max-height="150px" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/buyback.png"></v-img>
    <div class="card-spacer"></div>
    <v-card-actions>
      <v-btn href="https://57l5fnj0jt3.typeform.com/to/VnU8KRh7" class="deep-orange white--text">Fill out the form</v-btn>
    </v-card-actions>
 </v-card>
</template>

<script>
  export default {
    name: 'buyback',
    components: {
    },
    methods: {
    },
    data: function(){
      return {
      }
    }
  }
</script>
