<template>
  <v-card class="account-card">

    <v-card-title >
      Subscription Details
    </v-card-title>
    <v-card-subtitle>
      Find out when your subscription renews, change your billing information, or cancel your subscription
    </v-card-subtitle>
    <v-img contain max-height="150px" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/billing.png"></v-img>
    <div class="card-spacer"></div>
    <v-card-actions>
      <v-btn @click="account" class="blue white--text">View Details</v-btn>
    </v-card-actions>
 </v-card>
</template>

<script>
  export default {
    name: 'billing',
    components: {
    },
    methods: {
      account: function() {
        window.top.location.href= "https://completingthepuzzle.com/tools/recurring/get-subscription-access?passwordless=true";
      },
    },
    data: function(){
      return {
      }
    }
  }
</script>
