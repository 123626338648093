import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
// initial state
const state =  {subscriptionStatus: {days_left_before_next_shipment: 0, can_accelerate_without_rebilling: false, is_eligible_to_accelerate: false}}


const actions = {
  getSubscriptionStatus(store){
    subscriptionService.getSubscriptionStatus(store.rootGetters.getActiveSubscriptionId, data => {
      store.commit(types.SET_SUBSCRIPTION_STATUS, data);
    })
  }
}
const getters = {
  getSubscriptionStatus: (state) => {
    return state.subscriptionStatus
  }
}

// mutations
const mutations = {

  [types.SET_SUBSCRIPTION_STATUS] (state, status ) {
    state.subscriptionStatus = status;
  },
}

export default {
  state,
  actions,
  getters,
  mutations
}


