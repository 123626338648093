// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";
import normalize from "json-api-normalizer";

const state = {
  name: "",
  email: ""
}

// getters
const getters = {
}

const actions = {
  getCustomer(store) {
    store.commit(types.GET_CUSTOMER);
    subscriptionService.getCustomer(data => {
      if (data.customers.length > 1) {
        alert("System error! Please contact support@completingthepuzzle.com");
      } else {

        store.commit(types.SET_PLANS, data.plans);
        store.commit(types.SET_CUSTOMER, Object.values(data.customers)[0]);
        store.commit(types.SET_SUBSCRIPTIONS, data.subscriptions);
        store.commit(types.SET_LEVELS, data.levels);
        if (router.currentRoute.name == "default"){
          router.push({path: '/portal/subscriptions/'+Object.keys(data.subscriptions)[0]+'/home'})
        }

      }
    })
  },
  login(store, data) {

    store.commit(types.LOADING);
    axios.post('/api/v1/customer/login.json',
      {
        email: data.email,
        password: data.password
      })
      .then(response => {
        router.push("/portal/home");
        store.commit(types.SUCCESS, "Login successful");
      })
      .catch(e => {
        store.commit(types.ERROR, e.response.data.error.message)
      })

  },
  requestResetPassword(store, email) {
    store.commit(types.LOADING);
    axios.post('/api/v1/customer/request_reset_password.json',
      {email: email})
      .then(response => {
        store.commit(types.SUCCESS, "We've sent you an email with a link to update your password.");
      })
      .catch(e => {
        store.commit(types.ERROR, e.response.data.error.message)
      })
  },
  requestAccountActivationEmail(store, email){
    store.commit(types.LOADING);
    axios.post('/api/v1/customer/request_account_activation_email.json',
      {email: email})
      .then(response => {
        store.commit(types.SUCCESS, "Check your email account for instructions to create your account");
      })
      .catch(e => {
        store.commit(types.ERROR, e.response.data.error.message)
      })
  },
  resetPassword(store, data) {
    store.commit(types.LOADING);
    axios.post('/api/v1/customer/reset_password.json',
      data)
      .then(response => {
        store.commit(types.SUCCESS, "Password updated. Please sign in.");
      })
      .catch(e => {
        store.commit(types.ERROR, e.response.data.error.message)
      })
  },
}

// mutations
const mutations = {
  [types.SET_CUSTOMER] (state, customer ) {
    this.state.customer = customer
    FreshworksWidget('identify', 'ticketForm', {
      name: customer.attributes.name,
      email: customer.attributes.email,
    });
  },
  [types.GET_CUSTOMER] (state, customer ) {
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}