<template>
  <div>
    <v-container class="sticky-breadcrumbs">
      <v-breadcrumbs>
        <v-breadcrumbs-item exact :to="{path: '/portal/subscriptions/'+this.activeSubscriptionId+'/home'}">Home</v-breadcrumbs-item>
        <v-breadcrumbs-divider>
          <v-icon>mdi-chevron-right</v-icon>
        </v-breadcrumbs-divider>
        <v-breadcrumbs-item disabled>Update Puzzle Level</v-breadcrumbs-item>
      </v-breadcrumbs>
    </v-container>
    <v-container>
      <p>
        Update your level below and click save
      </p>
      <v-row class="justify-center">
        <v-col xs="12" md="4" v-for="level in levels" :key="level.id" style="flex-basis: inherit">
          <v-card :class="{ active: (level.id == localLevelId) }" >
            <v-card-title>
              {{ level.attributes.name }}
            </v-card-title>
            <v-card-subtitle v-html="getLevelDescription(level.attributes.name)">
            </v-card-subtitle>
            <v-img contain max-height="150px" :src="getImg(level.attributes.name)"></v-img>
            <div class="card-spacer"></div>
            <v-card-actions>
              <v-btn v-show="(level.id != localLevelId)" @click="selectLevel(level.id)" color="success">select</v-btn>
              <v-btn v-show="(level.id == localLevelId)" @click="" disabled>selected</v-btn>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
      <div class="mt-5">
        <v-btn
            color="success"
            class="mr-4"
            @click="submit"
        >
          Save
        </v-btn>
        <v-btn
            color="warning"
            class="mr-4"
            :to="{path: '/portal/subscriptions/'+this.activeSubscription.id+'/home'}"
        >
          Cancel
        </v-btn>
      </div>
    </v-container>

  </div>
</template>

<script>
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'manage_level',
  components: {
  },
  methods: {
    submit () {
      this.$store.dispatch("updateLevel", this.localLevelId)
    },
    selectLevel (id) {
      this.localLevelId = id;
    },
    getLevelDescription(name) {
      switch(name){
        case "beginner": return "300 pieces or less<br/>Great for people new to puzzling";
        case "intermediate": return "Between 300 and 800 pieces<br/> Great for those who want a challenge";
        case "expert": return "1000 pieces<br/>Best for those who really want to test their skills!";
      }
    },
    getImg(name) {
      return "https://completing-the-puzzle.s3.amazonaws.com/public/portal/levels/"+name+".png"
    },
    setLevelData() {
      this.localLevelId = this.activeSubscription.attributes.levelId;
    }
  },
  created: function(){
    this.$store.dispatch("getLevels");
  },
  mounted: function(){
    this.setLevelData();
  },
  computed: {
    ...mapState({
      levels: 'levels'
    }),
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
      activeSubscriptionId: 'getActiveSubscriptionId'
    }),
    beginner: function() {
      return this.levels[this.activeSubscription.attributes.levelId].name == "beginner"
    },
    intermediate: function() {
      return this.levels[this.activeSubscription.attributes.levelId].name == "intermediate"
    },
    expert: function() {
      return this.levels[this.activeSubscription.attributes.levelId].name == "expert"
    }
  },
  data: function(){
    return {
      localLevelId: null
    }
  },
}
</script>
<style scoped>
  .active {
    border: 2px solid #4caf50 !important;
  }
</style>
