// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";

const state = []

// getters
const getters = {
  getPuzzleProducts: (state) => {
    return state
  },
}

const actions = {
  getPuzzleProducts: (store) => {
    store.commit(types.GET_PUZZLE_PRODUCTS);
    subscriptionService.getPuzzleProducts(data => {
      store.commit(types.SET_PUZZLE_PRODUCTS, data.puzzleProducts);
    })
  },
}

// mutations
const mutations = {
  [types.SET_PUZZLE_PRODUCTS] (state, puzzleProducts ) {
    this.state.puzzleProducts = puzzleProducts
  },
  [types.GET_PUZZLE_PRODUCTS] (state, puzzleProducts ) {
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}