// initial state
import * as types from "../mutation-types";

const state = []

// getters
const getters = {
}

const actions = {

}

// mutations
const mutations = {
  [types.SET_RETURN_SHIPMENTS] (state, return_shipments ) {
    this.state.returnShipments = return_shipments
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}