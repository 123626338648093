<template>
  <div class="d-flex justify-center align-center splash">
    <v-card class="pa-6 login-card ma-6">
      <div class="text-center">
        <img src="/portal/ctp-logo.png" class="logo" />
        <h2 class="mt-2">Activate Your Account</h2>
        <p>Don't have an account yet? Enter your email below and we'll send you an email with instructions (if you have an account)</p>
      </div>
      <v-form
          ref="form"
          v-model="valid"
          lazy-validation
      >
        <v-text-field
            v-model="email"
            label="email"
            name="email"
            type="email"
            required
            v-on:keyup.enter="activateEmail"
        ></v-text-field>


        <v-btn
            color="success"
            class="mb-4"
            @click="activateEmail"
        >
          Send Me An Account Activation Email
        </v-btn>


      </v-form>
      <router-link :to="{ name: 'login'}">Return to login</router-link>
      <p class="mt-4 text-center">

        Still having issues? <br/>
        Click <a @click="openFreshdesk">here</a> to get help from support
      </p>
    </v-card>
  </div>
</template>

<script>
import {mapGetters, mapState} from "vuex";
export default {
  name: 'activate_account',
  components: {
  },
  methods: {
    openFreshdesk: function(){
      FreshworksWidget('open', 'ticketForm');
    },
    activateEmail: function(){
      this.$store.dispatch("requestAccountActivationEmail", this.email)
    }
  },
  computed: {
    ...mapGetters({
    }),
    ...mapState({
    }),
  },
  data: function(){
    return {
      'email': ''
    }
  }
}
</script>