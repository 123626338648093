// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";

const state = []

// getters
const getters = {
  getSubscriptionThemes: (state) => {return state},
  getSubscriptionThemesArray: (state) => {return Object.values(state).map( (obj) => obj.attributes.themeId )},
}

const actions = {
  getSubscriptionThemes(store) {
    store.commit(types.GET_SUBSCRIPTION_THEMES);
    subscriptionService.getSubscriptionThemes( store.getters.getActiveSubscription.id, data => {
      store.commit(types.SET_SUBSCRIPTION_THEMES, data.subscriptionThemes);
    })
  },
  updateSubscriptionThemes(store, themeIds) {
    subscriptionService.updateSubscriptionThemes( store.getters.getActiveSubscription.id, themeIds, data => {
      store.commit(types.SUCCESS, "Your themes have been updated");
      router.back();
    })
  }
}

// mutations
const mutations = {
  [types.SET_SUBSCRIPTION_THEMES] (state, subscriptionThemes ) {
    this.state.subscriptionThemes = subscriptionThemes || []
  },
  [types.GET_SUBSCRIPTION_THEMES] (state) {
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}