<template>
  <v-card>
    <v-card-title>
      Puzzle Preferences
    </v-card-title>
    <v-card-subtitle>
      Want to receive a different type of puzzle? Click below to change your puzzle themes
    </v-card-subtitle>
    <v-img contain max-height="150px" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/themes.png"></v-img>
    <v-card-actions>

    <v-btn :to="{ path: '/portal/subscriptions/'+this.activeSubscription.id+'/themes'}"  class="deep-purple white--text">Edit Preferences</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters} from "vuex";
export default {
  name: 'themes',
  components: {
  },
  methods: {
  },
  computed: {
    ...mapGetters({
      activeSubscription: 'getActiveSubscription',
    }),
  },
  data: function(){
    return {
    }
  }
}
</script>
