import * as types from '../mutation-types'
import subscriptionService from "../../api/subscription-service";
// initial state
const state = []

// getters
const getters = {
  getThemes: (state) => {
    return state
  },
}

const actions = {
  getThemes(store) {
    store.commit(types.GET_THEMES);
    subscriptionService.getThemes(data => {
      store.commit(types.SET_THEMES, data.themes);
    })
  },
}

// mutations
const mutations = {
  [types.GET_THEMES] (state) {

  },
  [types.SET_THEMES] (state, themes ) {
    this.state.themes = themes;
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}