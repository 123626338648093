<template>
  <div>
    <v-container>
      <v-row>
        <v-col >
          <div class="text-center mb-8">A puzzle from your wishlist will be shipped if it is available when your next puzzle is packed.  Add more puzzles to your wishlist to increase your chances of getting a puzzle from it!</div>
          <WishlistList :puzzleProducts="localWishlistProducts" :localWishlistProductIds="localWishlistProductIds">
          </WishlistList>


        </v-col>
      </v-row>

    </v-container>

  </div>
</template>

<script>
import WishlistList from './wishlist/wishlist_list'
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'catalog',
  components: {
    WishlistList
  },
  methods: {
    computeWishlistProducts: function() {
      if (this.localWishlistProductIds && this.localPuzzleProducts) {
        this.localWishlistProducts = new Array();
        for(let i=0; i < this.localWishlistProductIds.length; i++){
          this.localWishlistProducts.push(this.localPuzzleProducts[this.localWishlistProductIds[i]]);
        }
      }
    }
  },
  watch: {
    'wishlistProductIds': function () {
      this.localWishlistProductIds = this.wishlistProductIds;
      this.computeWishlistProducts();
    },
    'puzzleProducts': function() {
      this.localPuzzleProducts = this.puzzleProducts;
      this.computeWishlistProducts();
    }
  },
  created: function(){
  },
  mounted: function(){
    this.localWishlistProductIds = this.wishlistProductIds;
    this.localPuzzleProducts = this.puzzleProducts;
    this.computeWishlistProducts();
  },
  computed: {
    ...mapState({
    }),
    ...mapGetters({
      wishlistProductIds: 'getWishlistProductIds',
      puzzleProducts: 'getPuzzleProducts',
    })
  },
  data: function(){
    return {
      localWishlistProductIds: [],
      localWishlistProducts: [],
    }
  },
}
</script>
<style scoped>
.title-bg {
  background-color: rgba(0,0,0,0.2);
}
.card-subtitle {
  margin-bottom: 52px;
}
.bottom-actions{
  position: absolute;
  bottom: 0;
}
</style>
<style>
.theme {
  font-size: 13px;
  padding: 4px 8px;
  border-radius: 20px;
  margin-right: 4px;
  margin-top: 4px;
  display: inline-block;
}

.theme-landscape {
  background-color: #FFCDD2;
}
.theme-gradient {
  background-color: #E1BEE7;
}
.theme-fantasy {
  background-color: #D1C4E9;
}
.theme-disney {
  background-color: #B2EBF2;
}
.theme-destination {
  background-color: #B2DFDB;
}
.theme-collage {
  background-color: #90CAF9;
}
.theme-fine {
  background-color: #C8E6C9;
}
.theme-animals {
  background-color: #B3E5FC;
}
.theme-americana {
  background-color: #FFECB3;
}
.theme-holiday {
  background-color: #CFD8DC;
}
</style>
