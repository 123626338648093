<template>
  <div>
    <v-app-bar app flat>
      <!-- -->
      <img class="logo" src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/ctp-logo.png" />
      <div class="flex-grow-1"> </div>
      <v-menu
          bottom
          min-width="200px"
          rounded
          offset-y
      >
        <template v-slot:activator="{ on }">
          <v-btn
              icon
              x-large
              v-on="on"
          >
            <v-avatar
                color="blue"
                size="44"
            >
              <span class="white--text text-h5">{{ initials }}</span>
            </v-avatar>
          </v-btn>
        </template>
        <v-card>
          <v-list-item-content class="justify-center">
            <div class="mx-auto text-center">
              <v-avatar
                  color="blue"
              >
                <span class="white--text text-h5">{{ initials }}</span>
              </v-avatar>
              <p class="text-caption mt-1">{{ this.customer.attributes.name }}</p>
              <p class="text-caption mt-1">
                {{ this.customer.attributes.email }}
              </p>
              <v-divider class="my-3"></v-divider>
              <v-btn
                  depressed
                  rounded
                  text
                  href="/portal/sign_out"
              >
                sign out
              </v-btn>

            </div>
          </v-list-item-content>
        </v-card>
      </v-menu>
    </v-app-bar>
    <v-main>
      <subscriptions></subscriptions>
    </v-main>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import subscriptions from './subscriptions'
export default {
  components: {
    subscriptions
  },
  computed: {
    ...mapState({
      notifications: 'notifications',
      customer: 'customer'
    }),
    initials: function(){
      if (this.customer.attributes) {
        return this.customer.attributes.name.split(" ").map((n) => n[0]).join("");
      }else {
        return "";
      }
    },
    error_message_watcher: function(){
      return (this.notifications.error_message)
    },
    success_message_watcher: function(){
      return (this.notifications.success_message)
    },
  },
  created: function(){
    if (this.customer.id == null){
      this.$store.dispatch("getCustomer");
      this.$store.dispatch("getThemes");
      this.$store.dispatch("getBrands");
      this.$store.dispatch("getPuzzleProductThemes");
      this.$store.dispatch("getPuzzleProducts");
    }
  },
  data: function () {
    return {
      picker: new Date().toISOString().substring(0,10),
      user: {
        initials: this.initials,
        fullName: this.fullName,
        email: this.email
      }
    }
  }
}
</script>

<style scoped>
.logo {
  max-width: 150px;
}
p {
  font-size: 2em;
  text-align: center;
}
.fade-enter-active,
.fade-leave-active {
  transition-duration: 0.3s;
  transition-property: opacity;
  transition-timing-function: ease;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}
.fade-enter-active {
  transition-delay: 0.3s;
}

</style>
