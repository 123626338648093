// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";

const state = {
	shippingAddresses: [],
	currentShippingAddressId: null
}

// getters
const getters = {
	getShippingAddress: (state) => {return state.shippingAddresses[state.currentShippingAddressId]},
}

const actions = {
	updateShippingAddress(store, shippingAddress) {
		store.commit(types.UPDATE_SHIPPING_ADDRESS);
		axios.put('/api/v1/shipping-addresses/'+store.state.currentShippingAddressId+'.json',
			{
				data: {
					type: 'shipping-addresses',
					id: store.state.currentShippingAddressId,
					attributes: {
						"street1": shippingAddress.street1,
						"street2": shippingAddress.street2,
						"city": shippingAddress.city,
						"state": shippingAddress.state,
						"zip": shippingAddress.zip,
						"phone": shippingAddress.phone,
					}
				}
			})
			.then(response => {
				store.commit(types.SHIPPING_ADDRESS_UPDATED, response.data)
				store.commit(types.SUCCESS, "Your address was successfully updated")
				router.back();
			})
			.catch(e => {
				store.commit(types.ERROR, e.response.data.errors[0].title)
			})
	},
}

// mutations
const mutations = {
	[types.SET_SHIPPING_ADDRESS] (state, shippingAddresses ) {
		state.shippingAddresses = shippingAddresses;
		if (shippingAddresses){
			state.currentShippingAddressId = Object.keys(shippingAddresses)[0];
		}
	},
	[types.SHIPPING_ADDRESS_UPDATED] (state, shippingAddress ) {
		const key = shippingAddress.data.id;
		state.shippingAddresses[key].attributes.street1 = shippingAddress.data.attributes.street1;
		state.shippingAddresses[key].attributes.street2 = shippingAddress.data.attributes.street2;
		state.shippingAddresses[key].attributes.city = shippingAddress.data.attributes.city;
		state.shippingAddresses[key].attributes.state = shippingAddress.data.attributes.state;
		state.shippingAddresses[key].attributes.zip = shippingAddress.data.attributes.zip;
	},
}

export default {
	state,
	getters,
	actions,
	mutations
}