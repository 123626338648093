<template>
  <div>
    <v-container class="d-none">
      <v-row class="justify-center">
        <v-col sm="12" md="4">
          <v-card class="account-card">
            <v-card-text class="text-center">
              Trees planted
              <v-tooltip
                  v-model="showPlantTooltip"
                  top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>For every puzzle shipped, a tree is planted!</span>
              </v-tooltip>

            </v-card-text>
            <p class="large-number text-center">
              {{this.puzzleOrders.length}}
            </p>
          </v-card>
        </v-col>
        <v-col sm="12" md="4">
          <v-card class="account-card">
            <v-card-text class="text-center">
              Distance traveled
              <v-tooltip
                  v-model="showDistanceTooltip"
                  top>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on">
                    <v-icon>mdi-information-outline</v-icon>
                  </v-btn>
                </template>
                <span>Averaged round-trip distance for your puzzles</span>
              </v-tooltip>
            </v-card-text>
            <p class="large-number text-center">
              1200mi
            </p>
          </v-card>
        </v-col>
        <v-col  sm="12" md="4">
          <v-card class="account-card">
            <v-card-text class="text-center">
              Retail Value
            </v-card-text>
            <p class="large-number text-center">
              $68.60
            </p>
          </v-card>
        </v-col>
      </v-row>
    </v-container>

    <v-container class="mx-auto" style="max-width: 868px;">
      <div v-if="puzzleOrders.length == 0" class="text-center">
        Your puzzle history will show up here as you complete puzzles.
      </div>
      <h2>
        Puzzle History
      </h2>
      <v-timeline
          align-top
          dense
      >
        <v-timeline-item v-for="(puzzleOrder, id) in puzzleOrders" :key="puzzleOrder.id" icon="mdi-puzzle">
          <v-row class="pt-1">
            <v-col class="col-xs-1" >
              <div class="history-month">
                {{getOrderMonth(puzzleOrder)}}
              </div>
              <div class="history-date">
                {{getOrderDate(puzzleOrder)}}
              </div>
              <div class="history-year">
                {{getOrderYear(puzzleOrder)}}
              </div>
            </v-col>
            <v-col class="col-xs-11">
              <v-card >
                <v-img
                    v-bind:src="getPuzzleImageSrc(puzzleOrder.attributes.puzzleId)"
                    class="grey darken-4"
                ></v-img>
                <v-card-title>
                  {{getPuzzleName(puzzleOrder.attributes.puzzleId)}}
                </v-card-title>
                <v-card-text>
                  {{getBrandName(puzzleOrder.attributes.puzzleId)}}<br/>
                  {{getPuzzleDimensions(puzzleOrder.attributes.puzzleId)}}<br/>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-timeline-item>
      </v-timeline>
    </v-container>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {mapGetters} from "vuex";

export default {
  name: 'history',
  components: {
  },
  computed: {
    ...mapGetters({
      puzzleOrders: 'getPuzzleOrders',
      brands: 'getBrands',
    }),
    ...mapState({
      puzzles: 'puzzles',
      puzzleProducts: 'puzzleProducts',
    }),
  },
  created: function(){
  },
  methods: {
    getOrderMonth: function(puzzleOrder){
      let d = new Date(puzzleOrder.attributes.createdAt);
      return d.toLocaleString('default', { month: 'short' });
    },
    getOrderDate: function(puzzleOrder){
      let d = new Date(puzzleOrder.attributes.createdAt);
      let str = d.getDate().toString();
      if (str.length == 1){
        str = "0" + str;
      }
      return str;
    },
    getOrderYear: function(puzzleOrder){
      let d = new Date(puzzleOrder.attributes.createdAt);
      return d.getFullYear();
    },
    getPuzzleName: function(puzzleId){
      return this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.name;
    },
    getPuzzleDimensions: function(puzzleId){
      return this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.length+"\"x"+this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.width+"\"";
    },
    getBrandName: function(puzzleId){
      return this.brands[this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.brandId].attributes.name;
    },
    getPuzzleImageSrc: function(puzzleId){
      return this.puzzleProducts[this.puzzles[puzzleId].attributes.puzzleProductId].attributes.mediumImageSrc;
    }
  },
  data: function(){
    return {
      showPlantTooltip: false,
      showDistanceTooltip: false
    }
  }
}
</script>
<style scoped>
.large-number {
  font-weight: bold;
  font-size: 3rem;
}
.history-month {
  color: rgba(0,0,0,0.37);
}
.history-date {
  font-weight: bold;
  font-size: 1.8em;
  line-height: 0.9em;
}
.history-year {
  color: rgba(0,0,0,0.37);
  font-size: 0.9em;
}
</style>