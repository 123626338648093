// initial state
import * as types from "../mutation-types";
import subscriptionService from "../../api/subscription-service";

const state = {
}

// getters
const getters = {
  getCurrentPlan: (state, rootState) => {
    let plan = null;
    Object.values(state).forEach(value => {
      if (value.id == rootState.getActiveSubscription.attributes.planId) {
        plan = value;
      }
    })
    return plan;
  },
}

const actions = {
}

// mutations
const mutations = {
  [types.SET_PLANS] (state, plans ) {
    this.state.plans = plans
  },
}

export default {
  state,
  getters,
  actions,
  mutations
}