// initial state
import * as types from "../mutation-types";

const state = []

// getters
const getters = {
  getPuzzleOrders: (state) => {
    let keys = Object.keys(state)
    keys.sort((a, b) => {return parseInt(a) - parseInt(b)}).reverse();
    let sortedPuzzleOrders = new Array();
    keys.forEach(key => {
      sortedPuzzleOrders.push(state[key]);
    });
    return sortedPuzzleOrders;
  },
  getCurrentOrders: (state, rootState) => {
    let currentPlan = rootState.getCurrentPlan;
    let currentPuzzleOrders = new Array();
    if (currentPlan.attributes.hasNextDayShipping) {
      Object.values(state).forEach(value => {
        if (value.attributes.status == "assigned" || value.attributes.status == "fulfilled" || value.attributes.status == "queued_to_ship") {
          currentPuzzleOrders.push(value);
        }
      })
    } else{
      Object.values(state).forEach(value => {
        if (value.attributes.status == "assigned" || value.attributes.status == "fulfilled" || value.attributes.status == "queued_to_ship" || value.attributes.status == "return_in_transit") {
          currentPuzzleOrders.push(value);
        }
      })
    }

    return currentPuzzleOrders;
  },
  hasOpenOrders: (state) => {
    let flag = false;
    Object.values(state).forEach(value => {
      if (value.attributes.status == "open") {
        flag = true;
      }
    })
    return flag;
  }
}

const actions = {
  setPuzzleOrders(store, puzzleOrders) {
    store.commit(types.SET_PUZZLE_ORDERS, puzzleOrders);
  }
}

// mutations
const mutations = {
  [types.SET_PUZZLE_ORDERS] (state, puzzle_orders ) {
    this.state.puzzleOrders = puzzle_orders
  },
  [types.UPDATE_PUZZLE_ORDERS] (state, puzzleOrders ) {
    Object.keys(puzzleOrders).forEach(key => {
      this._vm.$set(this.state.puzzleOrders,  key, puzzleOrders[key])
    });

  },
}

export default {
  state,
  getters,
  actions,
  mutations
}