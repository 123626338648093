// initial state
import * as types from "../mutation-types";

const state = []

// getters
const getters = {
}

const actions = {

}

// mutations
const mutations = {
  [types.SET_SHIPMENTS] (state, shipments ) {
    this.state.shipments = shipments
  },

}

export default {
  state,
  getters,
  actions,
  mutations
}