<template>
  <v-card>

    <v-dialog
        v-model="dialog"
        width="100%"
        max-width="1080"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-img
            color="red lighten-2"
            dark
            v-bind="attrs"
            v-on="on"
            class="pointer-hover"
            src="https://completing-the-puzzle.s3.amazonaws.com/public/portal/2puzzlebannerad2.png"
        >
        </v-img>
      </template>

      <v-card>
        <v-card-title centered class="text-h6 yellow text-center" style="word-break: break-word">
          Limited Time Offer! (<CountdownTimer></CountdownTimer>)
        </v-card-title>

        <v-card-text>
          <p class="mt-2">
          You asked, we made it happen. You can now rent two puzzles at a time, together. Save 33% on your second puzzle when you upgrade now!
          </p>
          <p><i>How does it work?</i> You get two puzzles at a time and they always ship together. When you mail back your existing puzzle, we ship you two puzzles together.</p>
          <p><b>[BONUS]</b> All plans get expedited next puzzle shipping. Your two puzzle pack ships for free when we receive the initial return scan from USPS. You don't have to wait for your puzzles to make it back to our warehouse!</p>
          <p><i>Is my current plan prorated?</i> Yes, a support specialist will handle your account individually.</p>
          <p><i>Can I downgrade at a later time?</i> Yes, contact support and a specialist will be happy to help!</p>
          <p><i>How many puzzles per month can I expect to receive?</i> With expedited shipping, customers are getting up to 8 unique puzzles per month. That's $5.88 per puzzle (based on 6 month pricing)!</p>
          <p><i>More questions?</i> Contact support below</p>
          <p>
            Since this is exclusive to current customers, each upgrade is handled personally by a support specialist. Click below to upgrade:
          </p>
          <v-row>
            <v-col class="col-12 col-md-4">
              <h2>2 Puzzles Starter (Monthly)</h2>
              $50 per month<br/>
              Save 33% on your second puzzle<br/>
              FREE expedited next puzzle shipping!<br/>
              <v-btn class="green white--text" @click="upgradePlan('monthly')">Upgrade Now</v-btn>
            </v-col>
            <v-col class="col-12 col-md-4">
              <h2>2 Puzzles Puzzler (3 month)</h2>
              $48 per month<br/>
              <i>Save with upfront billing!</i> Pay only $144 for 3 months of two puzzle fun!<br/>
              FREE expedited next puzzle shipping!<br/>
              <v-btn class="green white--text" @click="upgradePlan('3 month')">Upgrade Now</v-btn>
            </v-col>
            <v-col class="col-12 col-md-4">
              <h2>2 Puzzles Pro Puzzler (6 months)</h2>
              $47 per month<br/>
              <i>Save with upfront billing!</i> Pay only $282 for 6 months of two puzzle fun!<br/>
              FREE expedited next puzzle shipping!<br/>
              <v-btn class="green white--text" @click="upgradePlan('6 month')">Upgrade Now</v-btn>
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer class="d-none d-md-flex"></v-spacer>
          <v-btn
              color="primary"
              text
              @click="dialog = false"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>




 </v-card>
</template>

<script>
  import CountdownTimer from "../util/countdowntimer";
  export default {
    name: 'two_puzzle_offer',
    components: {
      CountdownTimer
    },
    methods: {
      showDialog: function() {
        window.top.location.href= "https://completingthepuzzle.com/tools/recurring/login";
      },
      upgradePlan: function(typeOfPlan) {
        FreshworksWidget('identify', 'ticketForm', {
          subject: "Hi - I'd like to upgrade to the "+typeOfPlan+" 2 puzzle plan",
          description: "Please upgrade my account to the 2 puzzle plan, thank you!"
        });
        FreshworksWidget('open', 'ticketForm');
      }
    },
    data: function(){
      return {
        dialog: false,
        endDate: new Date()
      }
    }
  }
</script>
<style scoped>
.pointer-hover {
  cursor: pointer;
}
</style>
