import Vue from 'vue'
import Router from 'vue-router'
import Subscription from '../components/dashboard/subscription'
import Level from '../components/manage_level'
import ManageShippingAddress from '../components/manage_shipping_address'
import Themes from '../components/manage_themes'
import Home from '../components/home'
import Catalog from '../components/catalog'
import Wishlist from '../components/wishlist'
import History from '../components/history'
import Account from '../components/account'
import ForgotPassword from '../components/forgot_password'
import ResetPassword from '../components/reset_password'
import ActivateAccount from '../components/activate_account'
import Login from '../components/login'
import Portal from '../components/portal'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/portal/sign_in',
      name: 'login',
      component: Login
    },
    {
      path: '/portal/forgot_password',
      name: 'forgot_password',
      component: ForgotPassword
    },
    {
      path: '/portal/reset_password',
      name: 'reset_password',
      component: ResetPassword
    },
    {
      path: '/portal/activate_account',
      name: 'ActivateAccount',
      component: ActivateAccount
    },
    {
      path: '/portal/home',
      name: 'default',
      component: Portal,
      children: [
        {
          path: '/portal/subscriptions/:subscription_id',
          name: 'subscription',
          component: Subscription,
          props: true,
          children: [{
            path: 'home',
            name: 'home',
            component: Home,
            props: true
          }
            ,{
              path: 'catalog',
              name: 'catalog',
              component: Catalog,
              props: true
            },{
              path: 'wishlist',
              name: 'wishlist',
              component: Wishlist,
              props: true
            },{
              path: 'history',
              name: 'history',
              component: History,
              props: true
            },{
              path: 'account',
              name: 'account',
              component: Account,
              props: true
            },{
              path: 'level',
              name: 'level',
              component: Level,
              props: true
            },{
              path: 'themes',
              name: 'themes',
              component: Themes,
              props: true
            },{
              path: 'shipping_address',
              name: 'shipping_address',
              component: ManageShippingAddress,
              props: true
            }
          ]
        }
      ]
    }
  ]
})


export default router