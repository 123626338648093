<template>
  <div class="mb-16">
    <v-container class="d-none d-md-block">
      <v-tabs centered icons-and-text mobile-breakpoint="1">
        <v-tab :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/home'}" :key="activeSubscriptionId+'/home'">
          Home
          <v-icon>mdi-home-outline</v-icon>
        </v-tab>
        <v-tab :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/catalog'}" :key="activeSubscriptionId+'/catalog'">
          Catalog
          <v-icon>mdi-puzzle-outline</v-icon>
        </v-tab>
        <v-tab :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/wishlist'}" :key="activeSubscriptionId+'/wishlist'">
          Wishlist
          <v-icon>mdi-heart-outline</v-icon>
        </v-tab>
        <v-tab :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/history'}" :key="activeSubscriptionId+'/history'">
          History
          <v-icon>mdi-check-outline</v-icon>
        </v-tab>
        <v-tab :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/account'}" :key="activeSubscriptionId+'/account'">
          Account
          <v-icon>mdi-account-outline</v-icon>
        </v-tab>
      </v-tabs>
    </v-container>

    <v-bottom-navigation
        shift fixed class="d-md-none" dark
        :background-color="colorValue"
    >
      <v-btn :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/home'}" >
        <span>Home</span>
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-btn :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/catalog'}" >
        <span>Catalog</span>
        <v-icon>mdi-puzzle-outline</v-icon>
      </v-btn>
      <v-btn :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/wishlist'}" >
        <span>Wishlist</span>
        <v-icon>mdi-heart-outline</v-icon>
      </v-btn>
      <v-btn :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/history'}" >
        <span>History</span>
        <v-icon>mdi-check-outline</v-icon>
      </v-btn>
      <v-btn :to="{path: '/portal/subscriptions/'+activeSubscriptionId+'/account'}" >
        <span>Account</span>
        <v-icon>mdi-account-outline</v-icon>
      </v-btn>
    </v-bottom-navigation>
    <transition name="fade">
      <router-view :key="$route.fullPath"></router-view>
    </transition>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
export default {
  name: 'subscription',
  components: {
  },
  methods: {
    onRouteChanged: function(){
      this.changeColors();
    },

    changeColors: function(){
      switch (router.currentRoute.name) {
        case 'home': this.colorValue = 'blue';return
        case 'catalog': this.colorValue = 'cyan';return
        case 'wishlist': this.colorValue = 'pink';return
        case 'history': this.colorValue = 'green';return
        case 'account': this.colorValue = 'orange';return
      }
    },

    fetchSubscriptionData: function(){
      this.$store.dispatch("getSubscriptionData", this.subscription_id)
    }
  },
  mounted: function(){
    this.changeColors();
    this.fetchSubscriptionData();
  },
  watch: {
    // call again the method if the route changes
    '$route': 'onRouteChanged',

  },
  computed: {
    ...mapGetters({
      activeSubscriptionId: 'getActiveSubscriptionId'
    }),
  },
  props: ["subscription_id"],
  data: function(){
    return {
      colorValue: 'blue'
    }
  }
}
</script>
