<template>
  <div>
    <div v-if="hasOpenOrders && currentOrders.length == 0">
      Your puzzle is being picked
    </div>
    <div v-if="!hasOpenOrders && currentOrders.length == 0">
      Your puzzle has been returned and another is ready to ship. Click below to ship your puzzle.
      <br/>
      <br/>
    </div>
    <v-dialog
        v-model="dialog"
        width="100%"
        max-width="400">
      <template v-slot:activator="{ on, attrs }" >
        <v-btn class="blue white--text" v-bind="attrs" @click="manuallyCreatePuzzleOrders" v-if="!hasOpenOrders && currentOrders.length == 0"
               v-on="on">Ship My Next Puzzle</v-btn>
      </template>

      <v-card>
        <v-container>
          <v-row align-content="center" justify="center">
            <v-col class="text-center pa-6" cols="12">
              <p class="mt-2 font-weight-bold">
                Your puzzle is being picked!
              </p>
              <p>
                It will ship within 1 business day and you'll receive an email with tracking information.
                You can also find the tracking information in your account.
              </p>

              <v-col cols="6" style="margin: 0 auto" v-if="!hasOpenOrders">
                <v-progress-linear
                    color="blue accent-4"
                    indeterminate
                    rounded
                    height="6"
                ></v-progress-linear>
              </v-col>
              <div v-if="hasOpenOrders">
                Your order is received!<br/>
                <v-btn class="blue text--white" @click="dialog = false">Done</v-btn>
              </div>
            </v-col>
          </v-row>
        </v-container>

      </v-card>
    </v-dialog>




  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'manually_ship_puzzle',
  components: {
  },
  computed: {
    ...mapGetters({
      hasOpenOrders: 'hasOpenOrders',
      currentOrders: 'getCurrentOrders',
    }),
  },
  methods: {
    manuallyCreatePuzzleOrders: function(){
      this.$store.dispatch("manuallyCreatePuzzleOrders");
    },
  },
  data: function(){
    return {
      dialog: false
    }
  }
}
</script>
<style scoped>
.pointer-hover {
  cursor: pointer;
}
</style>
