<template>
  <div>

    <v-container>
      <v-breadcrumbs>
        <v-breadcrumbs-item exact :to="{path: '/portal/subscriptions/'+this.activeSubscriptionId+'/home'}">Home</v-breadcrumbs-item>
        <v-breadcrumbs-divider>
          <v-icon>mdi-chevron-right</v-icon>
        </v-breadcrumbs-divider>
        <v-breadcrumbs-item disabled>Update Puzzle Level</v-breadcrumbs-item>
      </v-breadcrumbs>
      <p>
        Update your address below and click save
      </p>
      <v-form ref="form"
              lazy-validation>
        <v-text-field label="address" v-model="localShippingAddress.street1" required :rules="[v => !!v || 'address Field is required!']"></v-text-field>
        <v-text-field label="apartment, suite, etc. (optional)" v-model="localShippingAddress.street2" ></v-text-field>
        <v-text-field label="city" v-model="localShippingAddress.city" :rules="[v => !!v || 'city is required!']" required></v-text-field>
        <v-select v-model="localShippingAddress.state"  item-text="name" item-value="abbreviation" :rules="[v => !!v || 'state is required!']" required :items="stateCodes" label="state"></v-select>
        <v-text-field label="zip" v-model="localShippingAddress.zip" :rules="[v => !!v || 'zip is required!']" required></v-text-field>
        <v-text-field label="phone" v-model="localShippingAddress.phone" required :rules="[v => (v || '').length == 10 || '10 digit phone number is requried'] "></v-text-field>
        <v-btn
            color="success"
            class="mr-4"
            @click="submit"
        >
          Save
        </v-btn>
        <v-btn
            color="warning"
            class="mr-4"
            :to="'/portal/subscriptions/'+this.activeSubscription.id+'/home'"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-container>

  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'manage_shipping_address',
  components: {
  },
  created: function(){
  },
  methods: {
    submit () {
      if (this.$refs.form.validate()) {
        this.$store.dispatch("updateShippingAddress", this.localShippingAddress)
      }
    },
    copyLocal (){
      if (this.localShippingAddress) {
        Object.keys(this.localShippingAddress).forEach(key => {
          this.localShippingAddress[key] = this.shippingAddress.attributes[key]
        });
      }
    }
  },
  computed: {
    ...mapGetters({
      shippingAddress: 'getShippingAddress',
      activeSubscription: 'getActiveSubscription',
      activeSubscriptionId: 'getActiveSubscriptionId'
    })
  },
  mounted: function(){
    this.copyLocal();
  },
  watch: {
    'shippingAddress': 'copyLocal'
  },
  data: function(){
    return {
      valid: true,
      localShippingAddress: {
        street1: null,
        street2: null,
        city: null,
        state: null,
        zip: null,
        phone: null
      },
      breadcrumbs: [
        {
          text: 'Dashboard',
          disabled: false,
          to: '/subscriptions/548'
        },
        {
          text: 'Update Shipping Address',
          disabled: true,
          href: '',
        }],
      stateCodes: [
        {
          "name": "Alabama",
          "abbreviation": "AL"
        },
        {
          "name": "Arizona",
          "abbreviation": "AZ"
        },
        {
          "name": "Arkansas",
          "abbreviation": "AR"
        },
        {
          "name": "California",
          "abbreviation": "CA"
        },
        {
          "name": "Colorado",
          "abbreviation": "CO"
        },
        {
          "name": "Connecticut",
          "abbreviation": "CT"
        },
        {
          "name": "Delaware",
          "abbreviation": "DE"
        },
        {
          "name": "District Of Columbia",
          "abbreviation": "DC"
        },
        {
          "name": "Florida",
          "abbreviation": "FL"
        },
        {
          "name": "Georgia",
          "abbreviation": "GA"
        },
        {
          "name": "Idaho",
          "abbreviation": "ID"
        },
        {
          "name": "Illinois",
          "abbreviation": "IL"
        },
        {
          "name": "Indiana",
          "abbreviation": "IN"
        },
        {
          "name": "Iowa",
          "abbreviation": "IA"
        },
        {
          "name": "Kansas",
          "abbreviation": "KS"
        },
        {
          "name": "Kentucky",
          "abbreviation": "KY"
        },
        {
          "name": "Louisiana",
          "abbreviation": "LA"
        },
        {
          "name": "Maine",
          "abbreviation": "ME"
        },
        {
          "name": "Maryland",
          "abbreviation": "MD"
        },
        {
          "name": "Massachusetts",
          "abbreviation": "MA"
        },
        {
          "name": "Michigan",
          "abbreviation": "MI"
        },
        {
          "name": "Minnesota",
          "abbreviation": "MN"
        },
        {
          "name": "Mississippi",
          "abbreviation": "MS"
        },
        {
          "name": "Missouri",
          "abbreviation": "MO"
        },
        {
          "name": "Montana",
          "abbreviation": "MT"
        },
        {
          "name": "Nebraska",
          "abbreviation": "NE"
        },
        {
          "name": "Nevada",
          "abbreviation": "NV"
        },
        {
          "name": "New Hampshire",
          "abbreviation": "NH"
        },
        {
          "name": "New Jersey",
          "abbreviation": "NJ"
        },
        {
          "name": "New Mexico",
          "abbreviation": "NM"
        },
        {
          "name": "New York",
          "abbreviation": "NY"
        },
        {
          "name": "North Carolina",
          "abbreviation": "NC"
        },
        {
          "name": "North Dakota",
          "abbreviation": "ND"
        },
        {
          "name": "Ohio",
          "abbreviation": "OH"
        },
        {
          "name": "Oklahoma",
          "abbreviation": "OK"
        },
        {
          "name": "Oregon",
          "abbreviation": "OR"
        },
        {
          "name": "Pennsylvania",
          "abbreviation": "PA"
        },
        {
          "name": "Puerto Rico",
          "abbreviation": "PR"
        },
        {
          "name": "Rhode Island",
          "abbreviation": "RI"
        },
        {
          "name": "South Carolina",
          "abbreviation": "SC"
        },
        {
          "name": "South Dakota",
          "abbreviation": "SD"
        },
        {
          "name": "Tennessee",
          "abbreviation": "TN"
        },
        {
          "name": "Texas",
          "abbreviation": "TX"
        },
        {
          "name": "Utah",
          "abbreviation": "UT"
        },
        {
          "name": "Vermont",
          "abbreviation": "VT"
        },
        {
          "name": "Virginia",
          "abbreviation": "VA"
        },
        {
          "name": "Washington",
          "abbreviation": "WA"
        },
        {
          "name": "West Virginia",
          "abbreviation": "WV"
        },
        {
          "name": "Wisconsin",
          "abbreviation": "WI"
        },
        {
          "name": "Wyoming",
          "abbreviation": "WY"
        }
      ]
    }
  }
}
</script>
